<!--<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
        <div class="main-banner ">
            <div>
                <div class="center1">
                    <h1 class="witha">Withaferin A</h1>
                    <h3 class="witha_1">
                        For including cardioprotective, anti-inflammatory, <br />immuno-modulatory,
                        anti-angiogenesis, <br /> [2] anti-metastasis and anti-carcinogenic properties.
                    </h3>
                </div>
            </div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-text">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>-->
        <!--<ng-template carouselSlide>
            <div class="main-banner"routerLink="/Bakuchiol">
               <div>
            <img src="../../../assets/img/Banner2_2.png"class="bak-img"  alt="nutrigene"/>
            <div class="center">
                <h3 class="pure">The Purest Cleanest Most Transparent</h3>
                <h1 class="bak" >Bakuchiol</h1>
                <h3 class="world">in the World</h3>
            </div>
        </div>  
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
        <div class="main-banner ">
            <div>
                
                <img src="../../../assets/img/Banner3_3.PNG" class="pelli-img"alt="nutrigene" />
                <div class="center2">
                    <h1 class="pelli">Pellitorine </h1>
                    <h3 class="pelli_1">
                        A potential anti-cancer lead compound against HL60 <br /> and MCF-7
                        cell lines
                    </h3>
                </div>

            </div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-text">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="main-banner ">
            <div>
                <img src="../../../assets/img/Banner4_4.png" class="alta-img" alt="nutrigene"/>
                <div class="center6">
                    <h1 class="nun">Alantolactone</h1>
                </div>
            </div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-text">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="main-banner ">
            <div>
                <img src="../../../assets/img/Banner5_5.png" class="nun-img" alt="nutrigene"/>
                <div class="center4">
                    <h1  class="nun">NuphyLLene</h1>

                </div>
            </div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-text">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="main-banner ">
            <div>
                <img src="../../../assets/img/Banner6_6.png"class="hydro_img"alt="nutrigene"/>
                <div class="center5">
                    <h1 class="nun">7-Hydroxy Frullanolide</h1>

                </div>
            </div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-text">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner ">
                <div>
                    <img src="../../../assets/img/Banner7_7.png" class="inno-img"alt="nutrigene" />
                    <div class="center3">
                        <h1 class="inno">INNOVATION. INTEGRITY. CUSTOMER EXCELLENCE.</h1>
                        <h3 class="inno_1">
                            IP Focused, Integrated Bakuchiol, Specialty Natural Extracts &
                            Fermentation Ingredients for Nutra, Biopharma, Food & Feed.
                        </h3>
                    </div>
                </div>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>-->
    <!--</owl-carousel-o>
</div>-->
<!--<div class="container mt-5">
    <div class="row news_2">

        <div class="col-lg-12 news">
            <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
                <div class="row news_1">

                    <b> News</b>
                </div>
                <marquee class="news-scroll marq" behavior="scroll" direction="left" onmouseover="this.stop();"
                         onmouseout="this.start();">


                    <span *ngFor="let item of dataResult; let i=index">
                        <span class="dot_1"></span> <a routerLink="/NewsAndEvents">{{item.NewsTitle}} </a>&nbsp;

                    </span>

                </marquee>
            </div>
        </div>
    </div>
</div>-->

<app-about></app-about>

<app-strategy></app-strategy>

<app-welcome></app-welcome>

<app-who-we-are></app-who-we-are>

<!--<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>-->

<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-team></app-team>

<!--<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>-->
