 <nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
            <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Sustainability</b></li>
            <div>Sustainability</div>

        </ul>
    </div>
</nav>
 
<div class="container-fluid">
    <div class="container"> 
        <h2 class="text-green headding" ><b>Welcome to Nutrigene – where Sustainable Farming & Sourcing meets Botanical Excellence</b></h2>
        <div class="row">
            <div class="col-lg-8">
                <p>
                    At Nutrigene, we're dedicated to not just delivering botanical extracts, but to revolutionizing the way they're responsibly sourced and cultivated. Our commitment to sustainability permeates every aspect of our operation, ensuring that every extract you receive is not just potent but ethically and environmentally sound.
                </p> <p>
                    With a focus on supply chain transparency and community empowerment, we strive to make a positive impact on both the environment and the communities we work with.

                </p>
            </div>
            <div class="col-lg-4">
                <img src="../../../../assets/img/NutrigeneLogo.png" alt="nutrigene" class="Nutrigenelogo">
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-lg-3">
                <img src="../../../../assets/img/Nutrigene/nutricals.webp" alt="nutrigene">
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-8">
                <h4 class="text-blue">
                    Sourcing Excellence
                </h4>
                <p>
                    Experience integrity in every extract. Our meticulous sourcing process prioritizes partnerships with local farmers who share our vision of sustainability. From field to formula, we trace each ingredient's journey, guaranteeing transparency and accountability every step of the way.
                </p>
            </div>
        </div> 
        <div class="row">
            <div class="col-lg-8">
                <br />  <br />
                <h4 class="text-blue">
                    Supply Chain Transparency
                </h4>
                <p>
                    Transparency isn't just a buzzword for us—it's a cornerstone of our philosophy. We believe in empowering our customers with knowledge about where their botanical extracts come from. From seed to extract to bottle, we ensure that our supply chain is traceable and ethical, and provide complete visibility into our supply chain, allowing you to trace the journey of every ingredient back to its source. By maintaining open communication with our farmers and partners, we guarantee that every botanical extract meets our rigorous standards for quality and sustainability.
                </p>
            </div>
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/supply.png" class="mt-5" />
            </div>
        </div><br />
    </div>
</div>
<div class="container-fluid " >
    <div class="container">


        <div class="row">
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/bakuchii.jpg" />

            </div>
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    The World's Largest Bakuchi Seed Farm for Bakuchiol
                </h4>
                <p>
                    Located in South India’s verdant hills, our 100 Acre protected bakuchi farm is the Largest and Only One of its kind in the world. Here, amidst sprawling fields, we cultivate bakuchi seeds with care and expertise, ensuring the highest quality Bakuchiol for our customers. Our dedication to excellence has made us a leader in the industry, setting the standard for bakuchi cultivation worldwide.
                </p>
            </div>

        </div>


        <div class="row">
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Solanum, Cestrum, and Rosemary Farms
                </h4>
                <p>
                    In addition to bakuchi, we operate the world’s largest Solanum & Cestrum farms, and some of the world’s largest, highest active content, pesticide-free rosemary farms. These plants are not only sources of premium botanical extracts but also integral parts of local ecosystems. Through responsible farming practices, we preserve biodiversity and protect endangered species.
                </p>
            </div>
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/Rosemary-farm.webp" class="mt-4" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/nagoya.jpg" class="mt-4" />
            </div>
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Following Nagoya Protocol
                </h4>
                <p>
                    As stewards of biodiversity, we are committed to upholding the Nagoya Protocol. By respecting the rights of indigenous peoples and local communities, we ensure that our sourcing practices are ethical and compliant with international regulations.
                </p>
            </div>

        </div>
        <br />
        <div class="row">
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Backward and Vertical Integration
                </h4>
                <p>
                    From farming to extraction, we control every aspect of our production process. This means we not only cultivate our botanicals but also integrate backward into the farming process. We have our own solanum, cestrum, rosemary and other high priority botanical farms, allowing us to oversee every step of the production chain and guaranteeing unparalleled quality from seed to extract. This backward and vertical integration allows us to maintain the highest standards of quality and sustainability while minimizing our environmental footprint.
                </p>
            </div>
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/Backward-Integration.jpg" class="mt-4" />
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/community.jpg" />

            </div>
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Community Empowerment
                </h4>
                <p>
                    Together, we sow the seeds of change. We believe in empowering the communities we work with. By supporting small-scale producers and empowering rural communities, we foster resilience and prosperity across the agricultural landscape. Join us in cultivating a brighter future, where sustainable farming isn't just a practice—it's a way of life.
                </p>
            </div>

        </div>
        <br />
        <div class="row">
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Regenerative Farming
                </h4>
                <p>Our commitment to regenerative farming goes beyond just sustainability – it's about restoring and revitalizing the land. Through practices such as crop rotation, soil enrichment, and water conservation, and preserving biodiversity, we aim to leave the earth better than we found it.</p>
            </div>
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/regenerative-agricolture-forigo-roter-italia-header.jpg" />
            </div>
        </div>

        <br />

        <div class="row">
            <div class="col-lg-4">


                <img src="../../../assets/img/Nutrigene/botanic.jpg" />
            </div>
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Botanical Active Enrichment Through Advanced Agronomy
                </h4>
                <p>At Nutrigene, we go beyond conventional farming practices to ensure the highest quality botanical extracts. Through targeted agronomy practices, we selectively breed and enrich the active compounds within our plants, enhancing their potency and efficacy. </p>
            </div>

        </div>
        <br />
        <div class="row">
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Targeted Agronomy Practices
                </h4>
                <p>Our team of experts employs advanced agronomic techniques tailored to each botanical species. From precise nutrient management to optimal irrigation strategies, we optimize growing conditions to maximize the concentration of active compounds.  </p>
            </div>
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/agronami.jpg" />
            </div>
        </div>
        <br />

        <div class="row">
            <div class="col-lg-3">
                <img src="../../../assets/img/Nutrigene/sustain.PNG" />

            </div>
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Stable and High-Quality Supply
                </h4>
                <p>
                    By enriching our botanicals through targeted agronomy practices, we ensure a stable and consistent supply of high-quality extracts. This reliability not only meets the demands of our customers but also supports long-term sustainability and growth.
                </p>
            </div>

        </div>
        <br />
        <div class="row">
            <div class="col-lg-8">
                <br />
                <h4 class="text-blue">
                    Commitment to Excellence
                </h4>
                <p>
                    At Nutrigene, excellence is not just a goal – it's a commitment. We continuously invest in research and development to push the boundaries of botanical enrichment, setting new standards for quality and performance.

                    Trust Nutrigene for stable, high-quality botanical extracts that deliver results every time.
                </p>
            </div>
            <div class="col-lg-4">
                <img src="../../../assets/img/Nutrigene/commitment.jpg" />
            </div>
        </div>


    </div>



</div>
