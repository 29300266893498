<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-xs-6">
                <a class="navbar-brand" routerLink="/">
                    <img src="../../../../assets/img/NutrigeneLogo.png" class="Nutrigenelogo" alt="nutrigene" />
                </a>
            </div>
            <div class="col-lg-3 col-xs-6">
                <div class="navbar-toggler" type="button" (click)="toggleClass()"style="float:right">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </div>
            </div>

        </div>
       
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto navv"  >
                <li class="nav-item"><span class="nav-link"><a routerLink="/about">Home</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Bakuchiol">Bakuchiol</a></span></li>
                <li class="nav-item">
                    <span class="nav-link">
                        <div class="dropdown">
                            <span class="dropbtn">
                                Products
                            </span>
                            <div class="dropdown-content">
                                <a href="/NovelStandardExtracts">Novel standard extracts</a>
                                <a href="/ActiveBotanicalPharmaIngredients" class="active">Botanical API's</a>
                                <a href="/StandardizedBotanicalSupplements">Dietary Supplements</a>
                                <a href="/PhytoChemicalReferenceStandards">Reference Standards</a>
                                <a href="/LeadPhytoChemical">PhytoChemicals</a>
                            </div>
                        </div>
                    </span>
                </li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Sustainability">Sustainability</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Gallery">Gallery</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/ContactUs">Contact&nbsp;Us</a></span></li>
                <li style="margin-top:2%">
                    <span class="nav-link">
                        <div>
                            <p-autoComplete placeholder="Search by CAS Number, Chemical Name...!" [(ngModel)]="selectedProduct" [suggestions]="searchprods" (completeMethod)="filterProducts($event)" field="ProductName" [dropdown]="false">
                                <ng-template let-country pTemplate="item">
                                    <div class="country-item" (click)="navigatingToSearchedProduct(country)">
                                        <div class="card p-1">{{country.ProductName}}&nbsp;|&nbsp;{{country.CasNumber}}&nbsp;|&nbsp;{{country.SubCategoryName}}&nbsp;|&nbsp;{{country.CommonName}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </span>
                </li>
                <li>
                    <span>
                        <a href="http://www.bdrpharma.com/" target="_blank">
                            <img src="../../../../assets/img/BDRlogo.jpeg" class="bdrlogo" alt="nutrigene" />
                        </a>
                    </span>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link"><a routerLink="/about">Home</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Bakuchiol">Bakuchiol</a></span></li>
                <li class="nav-item">
                    <span class="nav-link">
                        <div class="dropdown">
                            <span class="dropbtn">
                                Products
                                <i class="fa fa-caret-down"></i>
                            </span>
                            <div class="dropdown-content">
                                <a href="/NovelStandardExtracts">Novel standard extracts</a>
                                <a href="/ActiveBotanicalPharmaIngredients" class="active">Botanical API's</a>
                                <a href="/StandardizedBotanicalSupplements">Dietary Supplements</a>
                                <a href="/PhytoChemicalReferenceStandards">Reference Standards</a>
                                <a href="/LeadPhytoChemical">PhytoChemicals</a>
                            </div>
                        </div>
                    </span>
                </li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/Gallery">Gallery</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/ContactUs">Contact Us</a></span></li>
                <li >
                    <span>
                        <div>
                            <p-autoComplete class="sea" placeholder="Search your Product...!" [(ngModel)]="selectedProduct" [suggestions]="searchprods" (completeMethod)="filterProducts($event)" field="ProductName" [dropdown]="false">
                                <ng-template let-country pTemplate="item">
                                    <div class="country-item" (click)="navigatingToSearchedProduct(country)">
                                        <div>{{country.ProductName}}&nbsp;|&nbsp;{{country.CasNumber}}&nbsp;|&nbsp;{{country.SubCategoryName}}&nbsp;|&nbsp;{{country.CommonName}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</nav>
<style>
    input {
        width: 315px;
        margin-top: -5%;
    }
</style>
