import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-phyto-chemical-standards',
  templateUrl: './phyto-chemical-standards.component.html',
  styleUrls: ['./phyto-chemical-standards.component.scss']
})
export class PhytoChemicalStandardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
