import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';


//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
@Component({
  selector: 'app-novel-standard-extracts',
  templateUrl: './novel-standard-extracts.component.html',
  styleUrls: ['./novel-standard-extracts.component.scss']
})
export class NovelStandardExtractsComponent implements OnInit {

    HomeUrl: any;
    dataResult: any = [];
    arr: any[];
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) {  //
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });



    }

    ngOnInit(): void {
        this.GetProducts();
    }
    GetProducts() {
        debugger
        this.arr = []
        this.arr.push({

            CatId: '2'

        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/Nutrigene/Get_NU_CatProducts_basedoncatid";
        this.generalService.PostData(url, UploadFile).then(data => {
            // debugger
            this.dataResult = data;
            //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }







}
//export interface Product {
//  //PACKAGE_ID: number;
//  //PACKAGENAME: string;
//  //PACKAGECODE: string;
//  //PRICE: number;
//}

