<!--<div class="main-banner">
    <div class="d-table">
        <div>
            <img src="../../../assets/img/Banner6_6.png" class="hydro_img" alt="nutrigene"/>
            <div class="center5">
                <h1 class="nun">7-Hydroxy Frullanolide</h1>

            </div>
        </div>
    </div>
</div>-->
<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
            <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Products</b></li>
            <div>Lead Phyto Chemicals</div>

        </ul>
    </div>
</nav>

    <div id="blog" class="blog-area ptb-100" style="background:#fff">
        <div class="container">


            <div class="row mobileMargin mb-3">
                <div class="col-lg-4 col-md-4 col-sm-6 " *ngFor="let item of dataResult">
                    <div class="cards " (click)="navigatingToSelectedProduct(item)  ">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="single"><a> {{item.Catalog_no}}</a></div>
                            </div>
                            <div class="col-lg-7">
                                <a routerLink="/ActiveBotanicalPharmaIngredients" title="Share" (click)="sendWhatsapp(item)" style="color:deepskyblue;float:right">
                                    <i class="fa-solid fa-share-nodes" style="cursor:pointer;padding:5px"></i>
                                </a>
                            </div>
                        </div>
                        <div class="hover01 column card-container">
                            <div *ngIf="item.ProductImage != null || item.ProductImage != ''" class="image-container">
                                <figure>
                                    <img src="{{ HomeUrl + item.ProductImage }}" *ngIf="item.ProductImage" class="card-image" />
                                </figure>
                            </div>
                            <div *ngIf="item.ProductImage == null || item.ProductImage == ''" class="image-container">
                                <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="Nutrigene" style="width: 75%;" />
                            </div>
                        </div>

                        <hr class="hrl" />
                        <h5 class="card-title a_g99">{{item.ProductName}}</h5>

                        <div class="row">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-5 col-sm-5 col-xs-6">
                                <div class="casn_left" *ngIf="item.CasNumber"> CAS Number </div>
                                <div class="casn_left" *ngIf="item.OfferingGrades">Grades</div>
                                <div class="casn_left" *ngIf="item.Specification">Specification</div>
                                <div class="casn_left" *ngIf="item.Botanicalsource">Plant Source </div>
                                <div class="casn_left" *ngIf="item.TradeMark"> TradeMark </div>
                            </div>
                            <div class="col-lg-6 col-sm-7 col-xs-6">
                                <p class="casn_right">{{item.CasNumber}}</p>
                                <p class="casn_right">{{item.OfferingGrades}}</p>
                                <p class="casn_right">{{item.Specification}}</p>
                                <p class="casn_right">{{item.Botanicalsource}}</p>
                                <p class="casn_right">{{item.TradeMark}}</p>
                            </div>

                        </div>

                        <div class="row">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <style>
        .custcards {
            border: 1px solid #c1c1c1;
            padding: 11px;
            border-radius: 4px;
        }

        .cartpad {
            padding-right: 0
        }

        .a_g99 {
            /*width: 100%;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;*/
        }



        @media only screen and (max-width:600px) {
            .casn {
                margin-left: -30px;
                font-size: 12px;
            }

            .botanical {
                margin-left: -30px;
                font-size: 12px;
            }
        }
    </style>


