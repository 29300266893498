<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">

            <h2><span>Certifications</span></h2>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <!--<div class="team-slides">
        <owl-carousel-o [options]="teamSlides">
            <ng-template carouselSlide>
                <div class="single-team-box">
                    <div class="image">
                        <img src="../../../../assets/img/GMP.png" alt="team-img">
                    </div>

                    <div class="content">
                        <h3 class="title">GMP</h3>

                    </div>

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-team-box">
                    <div class="image">
                        <img src="../../../../assets/img/Halalfood.png" alt="team-img">
                    </div>

                    <div class="content">
                        <h3 class="title">Halalfood</h3>

                    </div>

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-team-box">
                    <div class="image">
                        <img src="./../../../assets/img/ISOcompany.png" alt="team-img">
                    </div>

                    <div class="content">
                        <h3 class="title">ISO</h3>

                    </div>

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-team-box">
                    <div class="image">
                        <img src="../../../../assets/img/ISOfood.png" alt="team-img">
                    </div>

                    <div class="content">
                        <h3 class="title">ISOfood</h3>

                    </div>

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-team-box">
                    <div class="image">
                        <img src="../../../../assets/img/Organiccertified.png" alt="team-img">
                    </div>

                    <div class="content">
                        <h3 class="title">Organic certified</h3>


                    </div>
                    </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-team-box">
                    <div class="image">


                        <img src="../../../../assets/img/natural.png" />
                    </div>
                    <div class="content">
                        <h3 class="title">100% Natural</h3>

                    </div>

                </div>
            </ng-template>
        </owl-carousel-o>
    </div>-->

        <div class="row mobileMargin mb-3 " style="margin:0 -22px;">

            <div class="col-lg-2 col-md-2 col-xs-6 col-xs-4">
                <img src="../../../../assets/img/GMP.webp" alt="team-img">
                <br />
                <h6 class="title">GMP</h6>

            </div>
            <div class="col-lg-2 col-md-2 col-xs-6 col-xs-4">
                <img src="../../../../assets/img/natural.webp"alt="nutrigene" />
                <br />
                <h6 class="title_1">100% Natural</h6>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">
                <img src="../../../../assets/img/Organiccertified.webp" alt="team-img">
                <br />
                <h6 class="title_2">Organic certified</h6>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">
                <img src="../../../../assets/img/ISOfood.webp" alt="team-img">
                <br />
                <h6 class="title_3">ISO food</h6>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-6  col-xs-4">
                <img src="./../../../assets/img/ISOcompany.webp" alt="team-img">
                <br />
                <h6 class="title_4">ISO</h6>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-6 col-xs-4">
                <img src="../../../../assets/img/Halalfood.webp" alt="team-img">
                <br />
                <h6 class="title_5">Halal food</h6>
            </div>
           
        </div>
    </div>

</div>
<!--<span class="post">Photographer</span>-->
                        
<!--<div class="social">
    <ul>
        <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
        <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
        <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
        <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
    </ul>
</div>-->
