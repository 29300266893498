import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router, ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-active-botanical-pharma-ingredients',
    templateUrl: './active-botanical-pharma-ingredients.component.html',
    styleUrls: ['./active-botanical-pharma-ingredients.component.scss']
})
export class ActiveBotanicalPharmaIngredientsComponent {
    whatsAppProductURL: string;

    HomeUrl: any;
    dataResult: any = [];
    arr: any[];
    ProductName: any;
 
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            this.ProductName = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();
        });



    }

    ngOnInit(): void {
        this.GetProducts();
    }
    homeSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        items: 1,
        autoHeight: true,
        smartSpeed: 750,
        autoplay: true,
        navText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ]
    }
    GetProducts() {
        debugger
        this.arr = []
        this.arr.push({

            CatId: '1'

        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/Nutrigene/Get_NU_CatProducts_basedoncatid";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.dataResult = data;
            //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    /*navigating to customer selected products from our products*/

    navigatingToSelectedProduct(data) {
        debugger
        var prodName = ""; var prodarr = []
        prodarr.push(data)
        var str = data.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.generalService.setProducts(prodarr)
        this.router.navigate(['/Product/' + prodName]);
    }



    sendWhatsapp(item) {
        debugger
        var prodName = " "
        var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/" + 'ActiveBotanicalPharmaIngredients' + prodName + '/' + item.CasNumber + '/' + item.Specification


        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }



}
//export interface Product {
//  //PACKAGE_ID: number;
//  //PACKAGENAME: string;
//  //PACKAGECODE: string;
//  //PRICE: number;
//}

