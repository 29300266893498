<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <!--<p><a routerLink="/" (click)="onClick('home')">Home</a></p>-->
                <h3 class="top">About </h3>
                <p style="line-height:2">Our core values are driven by Curiosity, Ambition & Humility in equal parts, Agility & Perseverance in doing whatever it takes to live up to our Commitments, and a Shared, Collaborative Accountability.</p>
               
                <!--<a href="http://nutrigeneadmin.gagriglobal.com/"><p>Admin Login</p></a>-->

            </div>
            <div class="col-lg-4">
                <h3 class="top">Quick Links</h3>
                <p><a routerLink="/about">About Us</a></p>

                <p><a routerLink="/Bakuchiol">Bakuchiol</a></p>
                <p><a routerLink="/NovelStandardExtracts">Novel Standard Extracts</a></p>
                <p><a routerLink="/ActiveBotanicalPharmaIngredients">Botanical API's</a></p>
                <p><a routerLink="/StandardizedBotanicalSupplements">Dietary Supplements</a></p>
                <p><a routerLink="/PhytoChemicalReferenceStandards">Reference Standards</a></p>
                <p><a routerLink="/LeadPhytoChemical">PhytoChemicals</a></p>

                <p><a routerLink="/Sustainability">Sustainability </a></p>
                <p><a routerLink="/Gallery">Gallery </a></p>


                <p><a routerLink="/ContactUs">Contact Us</a></p>
            </div>
            <!--<div class="col-lg-4">
        <h3 class="top">Address</h3>
        <p>201 Teja Block,</p>
        <p>My Home Navadweepa,</p>
        <p>HITEC City, Hyderabad,</p>
        <p>Telangana, India 500081.</p>
    </div>-->
            <!--<div class="col-lg-2">
        <h3 class="top">Media</h3>
        <p><a routerLink="/NewsAndEvents">News And Events</a></p>
        <p><a routerLink="/Gallery">Gallery</a></p>
        <p><a routerLink="/Dwonloadbrochures">Downloads</a></p>
     <br/>

    </div>-->
            <div class="col-lg-4 links">
                <h3 class="top">Get in touch</h3>

                <ul>
                    <li><i class="fas fa-map-marker-alt"></i>&nbsp; <span>Location:</span>201 Teja Block, My Home Navadweepa, HITEC City, Hyderabad, Telangana, India 500081.</li>
                    <!--<li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>-->
                </ul>
                <p style="    line-height:2"><i class="far fa-envelope"></i>&nbsp; <a href="mailto:sales1@nutrigene.bio."><span>Email:</span>sales1@nutrigene.bio</a></p>
                <p style="    line-height:2"><i class="fas fa-phone-alt"></i> &nbsp;<a href="tel:+91 95532 09779"><span>Call:</span>+91 95532 09779</a></p>
                <br />  <ul>
                    <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                    <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                    <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                    <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                </ul>
                
            </div>  </div>
    </div>
</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>
