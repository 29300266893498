
<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
            <li class="breadcrumb-item " aria-current="page"><b  style="color:white;">Products</b></li>
            <div>Novel standard extracts</div>

        </ul>
    </div>
</nav>
<!--<div class="breadcrumb">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ul >
                <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b>Home</b></a></li>
                <li class="breadcrumb-item" aria-current="page"><b>Products</b></li>
                <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy">Novel standard extracts</b></li>
            </ul>
        </nav>
        </div>
    </div>-->
    <div class="container paddingm">
        <div *ngFor="let item of dataResult;let even=even;let odd=odd;">

            <div class="row mobileMargin mb-3 gin_0 mobi" *ngIf="even">
                <div class="col-lg-5 pro">
                    <img src="{{HomeUrl+item.ProductImage}}" class="card_image1" alt="Nutrigene">
                </div>
                <div class="col-lg-7">
                    <div class="style">
                        <!--<b>{{item.Catalog_no}}</b>-->
                        <h4 class="pron">
                            <b><span class="red_1">{{item.ProductName}}</span></b>
                        </h4>
                        <div class="row">
                            <div class="Label_left"><b>Common Name </b> </div>
                            <div class="Label_right">{{item.CommonName}}</div>
                            <div class="Label_left"><b>Intended Use </b> </div>
                            <div class="Label_right">{{item.IntendedUse}}.</div>
                            <div class="Label_left"><b>Standardization </b></div>
                            <div class="Label_right">{{item.Standardization}}.</div>
                            <div class="Label_left"></div>
                            <div class="Label_right bold">{{item.PDescription}}</div>
                        </div>
                        <!--<b class="class_1">Common Name :</b><br />
                        <p class="comm">{{item.CommonName}}</p>
                        <b class="inte">Intended Use :</b> {{item.IntendedUse}}<br />
                        <b class="sta">Standardization :</b> {{item.Standardization}}
                        <p class="des">{{item.PDescription}}</p>-->
                    </div>
                  
                </div>
            </div>


            <div class="row mobileMargin mb-3 gin mar" *ngIf="odd">
                <div class="col-lg-4  desktop">
                    <img src="{{HomeUrl+item.ProductImage}}" class="card_image" alt="Nutrigene">
                </div>
                <div class="col-lg-8">
                    <div class="style">
                        <!--<b>{{item.Catalog_no}}</b>-->
                        <h4 class="name">
                            <b><span class="red">{{item.ProductName}}</span></b>
                        </h4>
                        <div class="row">
                            <div class="Label_left"><b>Common Name </b> </div>
                            <div class="Label_right">{{item.CommonName}}</div>
                            <div class="Label_left"><b>Intended Use </b></div>
                            <div class="Label_right">{{item.IntendedUse}}</div>
                            <div class="Label_left"><b>Standardization </b></div>
                            <div class="Label_right">{{item.Standardization}}</div>
                            <div class="Label_left"></div>
                            <div class="Label_right bold">{{item.PDescription}}</div>
                            <!--<b class="class1">Common Name :</b>
                            <p class="itemc">{{item.CommonName}}</p>
                             <b class="inte">Intended Use &nbsp;:&nbsp;</b> {{item.IntendedUse}}<br />
                              <b class="sta">Standardization :</b>&nbsp;&nbsp;{{item.Standardization}}
                              <p class="pdes">{{item.PDescription}}</p>-->
                            

                        </div>
                        

                    </div>
                </div>
                <div class="col-lg-4 proim mobile">
                    <img src="{{HomeUrl+item.ProductImage}}" class="card_image" alt="Nutrigene">
                </div>
            </div>

        </div>
    </div>





