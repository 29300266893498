import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
 declare var $:any

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
    @ViewChild('imageModal') imageModal!: ElementRef<HTMLDivElement>;
    HomeUrl: any;
    dataResult: any = [];
    dataResult1: any;
    isModalOpen: boolean = false; // Ensure isModalOpen is initialized
    modalVisible: boolean = false;
   
   
    
    imgCollection: any[] = [];
    selectedImage: string = '';
    
    currentIndex: number = 0;
    value: any = 1;
    imageSliderVisible: boolean = false;
    currentimg: any[];

    //imgCollection: any = [
    //    { image: 'assets/img/compressedimages/DSC00114.webp', thumbImage: 'assets/img/compressedimages/DSC00114.webp', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00127_21_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00127_21_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00135_23_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00135_23_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00140_25_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00140_25_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00178_31_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00178_31_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00187_32_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00187_32_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00218_35_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00218_35_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00222_36_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00222_36_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00232_38_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00232_38_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00236_40_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00236_40_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00244_41_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00244_41_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00272_42_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00272_42_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00279_44_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00279_44_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00289_45_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00289_45_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00347_54_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00347_54_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00349_55_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00349_55_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00355_57_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00355_57_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00398_62_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00398_62_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00400_63_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00400_63_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00416_65_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00416_65_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00432_69_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00432_69_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
    //    { image: 'assets/img/compressedimages/DSC00435_70_11zon.jpg', thumbImage: 'assets/img/compressedimages/DSC00435_70_11zon.jpg', title: 'Nutrigene', alt: 'Nutrigene' },
        
        
    //];
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, private renderer: Renderer2, private el: ElementRef) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }


    // Define currentIndex property in your component
    //openModal(image: any): void {
    // //   relatedImages  = []
    //    const relatedImages = this.getRelatedImages(image);

    //    // Show the modal with the related slide images
    //    this.showImageSlider(relatedImages);
    //}

    //getRelatedImages(selectedImage: any): any[] {
    //    // Implement your logic to determine related images based on the clicked image
    //    // For example, you might filter the imgCollection based on some criteria
    //    return this.imgCollection.filter(image => image.category === selectedImage.category);
    //}

    //showImageSlider5(relatedImages: any[]): void {
    //    // Show the image slider with the related images
    //    this.currentimg = relatedImages;
    //    this.imageSliderVisible = true;
    //}


    openModal(image: any,no): void {
        debugger
       // this.selectedImage = image;
       // this.modalVisible = true;
        this.value = no
        this.showImageSlider();
       // this.renderer.addClass(document.body, 'modal-open'); // Add class to body to prevent scrolling
    }

    showImageSlider(): void {
        debugger
        // Set any necessary variables or flags to show the image slider
        this.imageSliderVisible = true;
        // Initialize currentIndex or any other necessary variables for image navigation
        this.currentimg = this.imgCollection;
    }

    closeModal(): void {
        this.modalVisible = false;
        this.renderer.removeClass(document.body, 'modal-open'); // Remove class from body
    }

    nextImage() {
        this.currentIndex = (this.currentIndex + 1) % this.currentimg.length;
        this.selectedImage = this.currentimg[this.currentIndex].image;
    }

    prevImage() {

        this.currentIndex = (this.currentIndex - 1 + this.currentimg.length) % this.currentimg.length;
        this.selectedImage = this.currentimg[this.currentIndex].image;
    }



    ngOnInit(): void {
        this.getGallery();
    }

    getGallery() {
        debugger
        const url = "api/Nutrigene/Get_Gallar_Images";
        this.generalService.GetData(url).then((data: any[]) => {
            debugger
            this.imgCollection = data.map((obj: any) => ({
                image: "https://nutrigene.breakingindiaapp.com/webservices" + obj.Images,
                thumbImage: "https://nutrigene.breakingindiaapp.com/webservices" + obj.Images,
                title: 'Nutrigene',
                alt: 'Nutrigene'
            }));
        }).catch(err => {
            console.error('Error occurred while fetching gallery data:', err);
            // Handle error here
        });
    }

}
