import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import 'rxjs/add/operator/toPromise';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxCaptureModule } from 'ngx-capture';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { GMapModule } from 'primeng/gmap'
import { FilterPipe } from './Services/filter.pipe';// -> imported filter pipe
//import { Pipe, PipeTransform } from '@angular/core'
import { StickyNavModule } from 'ng2-sticky-nav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
//import { FormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { AccordionModule } from "ngx-accordion";
import {TabsModule} from 'ngx-tabset';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ParticlesModule } from 'ngx-particle';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'

export function HttpLoaderFactory(httpClient: HttpClient    ) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { DemoSidebarComponent } from './components/common/demo-sidebar/demo-sidebar.component';
import { LoginComponent } from './login/login.component';
import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { HomeComponent } from './pagelayout/home/home.component';
import { PageHeaderComponent } from './pagelayout/page-header/page-header.component';
import { PageFooterComponent } from './pagelayout/page-footer/page-footer.component';
import { ActiveBotanicalPharmaIngredientsComponent } from './pagelayout/active-botanical-pharma-ingredients/active-botanical-pharma-ingredients.component';
import { NovelStandardExtractsComponent } from './pagelayout/novel-standard-extracts/novel-standard-extracts.component';
import { StandardizedBotanicalSupplementsComponent } from './pagelayout/standardized-botanical-supplements/standardized-botanical-supplements.component';
import { PhytoChemicalReferenceStandardsComponent } from './pagelayout/phyto-chemical-reference-standards/phyto-chemical-reference-standards.component';
import { LeadPhotoChemicalComponent } from './pagelayout/lead-photo-chemical/lead-photo-chemical.component';
import { PhytoChemicalStandardsComponent } from './pagelayout/phyto-chemical-standards/phyto-chemical-standards.component';
import { AboutUsComponent } from './pagelayout/about-us/about-us.component';
import { ContactUsComponent } from './pagelayout/contact-us/contact-us.component';
import { TestingComponent } from './components/common/testing/testing.component';
import { EnquiryComponent } from './pagelayout/enquiry/enquiry.component';
import { NewsAndEventsComponent } from './pagelayout/news-and-events/news-and-events.component';
import { GalleryComponent } from './pagelayout/gallery/gallery.component';
import { DownloadBrochersComponent } from './pagelayout/download-brochers/download-brochers.component';
import { ProductDetailsComponent } from './pagelayout/product-details/product-details.component';
import { BakuchiolComponent } from './pagelayout/bakuchiol/bakuchiol.component'
import { OgMetaComponent } from '../../src/app/pagelayout/og-meta/og-meta.component';
import { SustainabilityComponent } from '../../src/app/pagelayout/sustainability/sustainability.component'


@NgModule({
    declarations: [
        FilterPipe, // -> added filter pipe to use it inside the component
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        ServicesTwoComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        HomeNineComponent,
        HomeTenComponent,
        HomeElevenComponent,
        HomeTwelveComponent,
        HomeThirteenComponent,
        BlogDetailsComponent,
        DemoSidebarComponent,
        LoginComponent,
        PagelayoutComponent,
        HomeComponent,
        PageHeaderComponent,
        PageFooterComponent,
        ActiveBotanicalPharmaIngredientsComponent,
        NovelStandardExtractsComponent,
        StandardizedBotanicalSupplementsComponent,
        PhytoChemicalReferenceStandardsComponent,
        LeadPhotoChemicalComponent,
        PhytoChemicalStandardsComponent,
        AboutUsComponent,
        ContactUsComponent,
        TestingComponent,
        EnquiryComponent,
        NewsAndEventsComponent,
        GalleryComponent,
        DownloadBrochersComponent,
        ProductDetailsComponent,
        BakuchiolComponent,
        OgMetaComponent,
        SustainabilityComponent,
       
    ],
    imports: [GMapModule,
        NgxCaptureModule,  NgImageSliderModule,
        ReactiveFormsModule,//Add if needed 
        FormsModule,     //Add if needed
        AutoCompleteModule,
        ToastModule,
        BrowserModule,
        AppRoutingModule,
        StickyNavModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
        NgxScrollTopModule,
        CarouselModule,
        FormsModule,
        AccordionModule,
        CountUpModule,
        TabsModule,
        TooltipModule,
        NgxTypedJsModule,
        ParticlesModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        ],
    bootstrap: [AppComponent]
})
export class AppModule { }
