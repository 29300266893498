
<video autoplay loop class="video">
    <source src="../../../assets/img/Nutrigene/oil-pour-banner.webm" type="video/webm" />
</video>

<div class="card"style="padding:18px">
    <h5 style="color: #0baddf;font-weight:bold;line-height:1.5">
        WORLD'S ONLY CRYSTAL CLEAR BAKUCHIOL COLORLESS. ODORLESS. FREEDOM TO FORMULATE
        IN ANY CONCENTRATION & MEDIA WITHOUT CHANGING PRODUCT COLOR & CHARACTER
    </h5>
</div>





<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <h1>Our Flagship Product BAKUCHIOL</h1>
            <h6>THE PUREST CLEANEST MOST TRANSPARENT BAKUCHIOL IN THE WORLD.</h6>
            <ul>
                <li>Proprietary Green Process with Liquid Gold Clarity & Near-Zero Furocoumarins (Psoralen & Angelicin).</li>
                <li>Bakuchiol is a meroterpene (a chemical compound having a partial terpenoid structure) in the class terpenophenol, from Psoralea corylifolia seeds as botanical source.</li>
                <li>Bakuchiol is a useful compound against oral pathogens and has a great potential for use in food additives and mouthwashes for preventing and treating dental caries. The cell growth of S. mutans was inhibited in a bakuchiol concentration dependent manner and the growth of S. mutans was completely prevented with 20 μg of bakuchiol/mL.</li>
                <li>Bakuchiol is an effective vitiligo treatment and also treats related skin diseases and conditions. It shrinks white spots on skin and reduces pigmentation.</li>
                <li>Bakuchiol is the first natural alternative to Retinol without having any of the negatives associated with retinol. It is photochemically and hydrolytically stable and can be used during the day.</li>
                <li>Multiple comparative studies revealed Bakuchiol to be true alternative to retinol. Retinol-like functionality was further confirmed for type I, III and IV collagens and aquaporin 3 by ELISA and histochemistry.</li>
                <li>Many clinical studies showed significant improvement in lines and wrinkles, pigmentation, elasticity, firmness and overall reduction in photo-damage without usual retinol-associated undesirable effects.</li>
                <li>Commercial use of Bakuchiol in a wide-variety of skin care and treatment products has begun and is expanding. Bakuchiol has now become a sought after ingredient for developing broad-spectrum anti-aging products.</li>
            </ul>
        </div>
        <div class="col-lg-4">

            <img src="../../../assets/img/compressedimages/DSC00168_29_11zon.webp" alt="Your Image"style="margin-bottom: 5%;"><br />
            <img src="../../../assets/img/compressedimages/DSC00355_61_11zon.webp" class="img" alt="Your Image" />
        </div>
    </div>
</div>




<div class="container">
    <div class="container" style="margin-top:3%">
        <div>
            <h2 style="color: #0baddf;font-weight:bold">
                Application FAQs
            </h2>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <br />
            <div style="position: relative;">
                <img src="../../../assets/img/Nutrigene/ckoil1.webp" class="img_app" style="border-radius:5px;" />
                <!--<img src="../../../assets/img/Nutrigene/applications.jpg"  />-->
            </div>
        </div>
        <div class="col-lg-6">
            <br /> <br />

            <ul style="padding:10px">
                <li>Bakuchiol works as an anti-inflammatory and an antioxidant so those who suffer from dry, sensitive skin and can't tolerate a retinol can use in all cosmetics.</li>
                <li>The antibacterial properties of bakuchiol also mean that it's great for those with acne and oily skin.</li>
                <li>Bakuchiol is likely the only agent after retinoic acid shown to be effective against multiple pathophysiologic features of acne.</li>
                <li>Bakuchiol has a few extra benefits over retinoids that are worth mentioning:</li>
                <li>Bakuchiol may also have a suppressive effect on melanin-producing cells in skin, which may make it a beneficial ingredient to lighten dark spots or improve uneven skin tone due to sun damage.</li>
            </ul>
        </div>
    </div>
</div><br />


<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <ul>
                <li>Bakuchiol also has antifungal activity, particularly against the type of fungi that cause athlete's foot (medically, Tinea pedis).</li>
                <li>Safety and Toxicology</li>
                <li>Bakuchiol has been shown to activate a number of chemical pathways in skin cells that ultimately lead to improved collagen production, decreased collagen breakdown and reduction of melanin (pigment) synthesis. The overall net effect seems to be an improvement in fine lines, wrinkles and pigmentation which are commonly associated with the natural ageing process of the skin.</li>
                <li>Bakuchiol has an excellent safety profile and was shown to be nonirritating and non-sensitizing based on human repeat insult patch testing, has no photo- or hydrolytic stability issues, and thus can be used throughout the day</li>
                <li>It is a potent antioxidant It is anti-inflammatory, which might be beneficial for acne and similar skin concerns and might help in reducing the side effects of application.</li>
                <li>It is photostable, which is why it is safe to use in the mornings, Retinoids do react with UV-light, so their use should be limited to night time.</li>
                <li>It is always vegan. Some retinoids might come from animal sources. For vegans and people that can’t tolerate retinoid products though, Bakuchiol might be a great alternative indeed.</li>

            </ul>
        </div>
        <div class="col-lg-4">

            <img src="../../../assets/img/compressedimages/DSC00400_63_11zon.webp" class="img" alt="Your Image" />
        </div>
    </div>
</div>


<br />

