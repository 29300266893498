<div class="strategy-area"style="padding-bottom:0px;margin-bottom:0px">
    <div class="container">
        <div class="row">
            <h3 style="line-height: 1.3; margin-bottom: 35px;  margin-top:40px;" >WORLD'S ONLY CRYSTAL CLEAR BAKUCHIOL COLORLESS. ODORLESS. FREEDOM TO FORMULATE IN ANY CONCENTRATION & MEDIA WITHOUT CHANGING PRODUCT COLOR & CHARACTER.</h3>
       <br />
            
            <div class="col-lg-5 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/BakuchiolH1.png" alt="nutrigene" style="border-radius:18px;">
                </div>
            </div>
            <div class="col-lg-7 col-md-12" routerLink="/Bakuchiol">
                <div class="strategy-content ">
                    <div class="section-title">
                        <h2 style="color: #0baddf">Our Flagship Product BAKUCHIOL</h2>
                        <h4>THE PUREST CLEANEST MOST TRANSPARENT BAKUCHIOL IN THE WORLD.</h4> <br />
                        <p>Proprietary Green Process with Liquid Gold Clarity & Near-Zero Furocoumarins (Psoralen & Angelicin).</p> <br />
                        <p>Bakuchiol is a meroterpene (a chemical compound having a partial terpenoid structure) in the class terpenophenol, from Psoralea corylifolia seeds as botanical source. </p> <br />
                        <p>
                            Bakuchiol is a useful compound against oral pathogens and has a great potential for use in food additives and mouthwashes for preventing and treating dental caries.
                            The cell growth of S. mutans was inhibited in a bakuchiol concentration dependent manner and the growth of S. mutans was completely prevented with 20 μg of bakuchiol/mL.
                        </p> 
                    </div>
                   <a routerLink="/Bakuchiol"><div style="text-align:right;" class="read"> Read more&nbsp;<img style="width:40px" src="https://static-00.iconduck.com/assets.00/arrow-circle-right-icon-256x256-u0nux4l1.png" /></div></a> 

                    <!--<ul class="features-list">
        <li><i class="fa fa-check"></i>Creative Design</li>
        <li><i class="fa fa-check"></i>Retina Ready</li>
        <li><i class="fa fa-check"></i>Responsive Design</li>
        <li><i class="fa fa-check"></i>Modern Design</li>
        <li><i class="fa fa-check"></i>Awesome Design</li>
        <li><i class="fa fa-check"></i>Digital Marketing & Branding</li>
    </ul>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
                </div>
            </div>
        </div>

        <!--<div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Highly Creative Solutions</h4>
                        <h2>Our <span>Mission</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Creative Design</li>
                        <li><i class="fa fa-check"></i>Retina Ready</li>
                        <li><i class="fa fa-check"></i>Responsive Design</li>
                        <li><i class="fa fa-check"></i>Modern Design</li>
                        <li><i class="fa fa-check"></i>Awesome Design</li>
                        <li><i class="fa fa-check"></i>Digital Marketing & Branding</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>
        </div>-->
    </div>
    <!--<div class="shape1"><img src="assets/img/shape1.png" alt="nutrigene"></div>-->
</div>

<style>
    .read:hover{
        color:green;
        cursor:pointer;
    }
    .strategy-image {
        width: 100%;
        height: 68%;
        border-radius: 18px;
        background-image: url(../../../../assets/img/BakuchiolH1.webp);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .btn {
        padding: 10px 30px;
        border-radius: 30px;
        transition: .5s;
        transition: var(--transition);
        font-size: 16px;
        font-weight: 500;
        margin-top: -76px;
    }
</style>
