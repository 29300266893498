<div class="main-banner">
    <div class="d-table">
        <div>
            <img src="../../../assets/img/NewsEvents.png" style="width:100%; overflow:hidden;" />
            <!--<div class="center">
                <h3>The latest News and Events</h3>
                <b style="font-size:100px">Nutrigene</b>
                <h3>in the World</h3>
            </div>-->
        </div>
    </div>
</div>

<!--<div class="container" style="margin-top:20px">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home"><b>Home</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b> News and</b>&nbsp; <b style="cursor:pointer;color:darkblue" (click)="rating(ratingsec)">Events</b> </li>
        </ol>
    </nav>
    <b style="cursor:pointer; font-size:40px;color:#01119f"> Newes</b>

     <div class="container">
            <div *ngFor="let item of dataResult;">
                <div class="row" style="margin:0 -22px;">
                    <div class="col-lg-5 ">
                        <p *ngIf="item.NewsImage != null ||item.NewsImage != '' ">
                            <img src="{{HomeUrl+item.NewsImage}}" class="card_image" alt="Nutrigene" *ngIf="item.NewsImage" style="">
                        </p>
                        <p *ngIf=" item.NewsImage == null || item.NewsImage == '' ">
                            <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="Nutrigene">
                        </p>
                    </div>
                    <div class="col-lg-8">
                        <div class="style">
                            <h4 style="font-family: open sans!important;font-size: 36px;"><b><span style="color: #0e9247;font-size: 19px;font-family: 'Poppins';">{{item.NewsTitle}}</span></b></h4><br />

                            <p style="color: #43434a;font-size: 18px;">{{item.NewsDescription}}</p><br />

                        </div>


                        <b> </b>
                    </div>
                    <b style=" margin-left: 63pc; color: #01119f;">{{item.CreatedDate | date: 'mediumDate'}}</b>
                </div>
                <br /><br />

            </div>

        </div>

    </div>

    <div class="container" style="margin-top:20px" #ratingsec>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">

                <li class="breadcrumb-item bread_active" aria-current="page"> <b style="cursor:pointer; font-size:40px;color:#01119f">Events</b> </li>
            </ol>
        </nav>



            <div *ngFor="let item of dataResult1;">
                <div class="row">
                    <div class="col-lg-4">
                        <p *ngIf="item.NewsImage != null ||item.NewsImage != '' ">
                            <img src="{{HomeUrl+item.NewsImage}}" class="card_image" alt="Nutrigene" *ngIf="item.NewsImage" style="">
                        </p>
                        <p *ngIf=" item.NewsImage == null || item.NewsImage == '' ">
                            <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="Nutrigene">
                        </p>
                    </div>
                    <div class="col-lg-8">
                        <div class="style">
                            <h4 style="font-family: open sans!important;font-size: 36px;"><b><span style="color: #0e9247;font-size: 19px;font-family: 'Poppins';">{{item.EventsTitle}}</span></b></h4><br />

                            <p style="color: #43434a;font-size: 18px;">{{item.EventsDescription}}</p><br />






                        </div>

                    </div>
                    <b style=" margin-left: 63pc; color: #01119f;">{{item.CreatedDate | date: 'mediumDate'}}</b>
                    <br /><br />

                </div>

        </div>

    </div>-->

<div class="container" style="margin-top:20px">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home"><b>Home</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b> News and</b>&nbsp; <b style="cursor:pointer;color:darkblue" (click)="rating(ratingsec)">Events</b> </li>
        </ol>
    </nav>
    <b style="cursor:pointer; font-size:40px;color:#01119f"> News</b>

    <div class="card">

        <div class="row" *ngFor="let item of dataResult;" style="margin-bottom: 42px;margin-left: 4px;">
            <div class="col-lg-4">
                <p *ngIf="item.NewsImage != null ||item.NewsImage != '' ">
                    <img src="{{HomeUrl+item.NewsImage}}" class="card_image" alt="Nutrigene" *ngIf="item.NewsImage" style="">
                </p>
                <p *ngIf=" item.NewsImage == null || item.NewsImage == '' ">
                    <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="Nutrigene">
                </p>

            </div>
            <div class="col-lg-8">

                <h4 style="font-family: open sans!important;font-size: 36px;"><b><span style="color: #0e9247;font-size: 19px;font-family: 'Poppins';">{{item.NewsTitle}}</span></b></h4><br />

                <p style="color: #43434a;font-size: 18px;">{{item.NewsDescription}}</p><br />


            </div>
            <b style=" text-align: right; color: #01119f;">{{item.CreatedDate | date: 'mediumDate'}}</b>
        </div>

    </div><br />
</div>






<div class="container" style="margin-top:20px" #ratingsec>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">

            <li class="breadcrumb-item bread_active" aria-current="page"> <b style="cursor:pointer; font-size:40px;color:#01119f">Events</b> </li>
        </ol>
    </nav>


    <div class="card" *ngFor="let item of dataResultMain;">

        <div class="row" style="margin-bottom: 42px;margin-left: 4px;">
            <div class="col-lg-4">
                <i class="fa-solid fa-calendar-days" style="font-size:23px"></i><b style=" color: #01119f; margin-left: 26px; margin-top: -28px; font-size: 23px;">{{item.EventsDate | date: 'mediumDate'}}</b>
            </div>
            <div class="col-lg-8">
                <h4 style="font-family: open sans!important;font-size: 36px;"><b><span style="color: #0e9247;font-size: 19px;font-family: 'Poppins';">{{item.EventsTitle}}</span></b></h4><br />
                <p style="color: #43434a;font-size: 18px;">{{item.EventsDescription}}</p><br />
            </div>
        </div>
        <div *ngFor="let imgs of dataResult1;">
            <div class="row mobileMargin mb-3" *ngIf="imgs.EventsID == item.EventsID">
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <div class="card card_border single-box" style="padding:0px 0px 0px 0px">
                        <p *ngIf="item.EImage != null ||item.EImage != '' ">
                            <img src="{{HomeUrl+item.EImage}}" class="card_image" alt="Nutrigene" *ngIf="item.EImage">
                        </p>
                        <p *ngIf=" item.EImage == null || item.EImage == '' ">
                            <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="Nutrigene">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div><br />
</div>


















