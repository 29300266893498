<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
            <li class="breadcrumb-item " aria-current="page"><a href="/product-details"><b style="color:white;">Products</b></a></li>
            <li style="color:#ffffff;text-transform:lowercase">  / <a>{{CategoryName}}</a> / <a>{{SubCategoryName}}</a> / <a style="cursor:pointer;color:#ffffff;" (click)="navigatingtoFarmerProduces(productDetails[0].ProductName)">{{productDetails[0].ProductName}}</a>
            </li>

        </ul>
    </div>
</nav>
<!--<div class="container">
    <ol class="breadcrumbs">
        <li class="home home_1 ">
            <a routerLink="/"><i class="fa fa-home mr-2" style="color:#ffffff"></i></a>
        </li>
        <li class="prona na_1" style="color:#ffffff">
            / <a>{{CategoryName}}</a> / <a>{{SubCategoryName}}</a> / <a style="cursor:pointer;color:#ffffff;" (click)="navigatingtoFarmerProduces(productDetails[0].ProductName)">{{productDetails[0].ProductName}}</a>
        </li>
    </ol>
</div>-->
<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 ">
                <div class="prod_1"> PRODUCT DETAILS</div>
                <div class="decCard">
                    <div class="row">
                        <div class="col-lg-9 col-7">
                            <p *ngIf="productDetails[0].ProductImage != null ||productDetails[0].ProductImage != '' ">
                                <img src="{{HomeURL+productDetails[0].ProductImage}}" class="img_0" alt="Nutrigene" *ngIf="productDetails[0].
                                     ProductImage">
                            </p>
                            <p *ngIf=" productDetails[0].ProductImage == null || productDetails[0].ProductImage == '' ">
                                <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="Nutrigene">
                            </p>
                        </div>
                        <div class="col-lg-3 col-5 ">
                            <div class="row">
                                <div class="col-lg-12">
                                    <img class="img1" src="../../../assets/img/NutrigeneLogo.png" />
                                </div>
                                <div class="col-lg-6" style="margin-left: 1%;"><small>Sales@nutrigene.bio</small></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <h4 class="mainHeader head">{{productDetails[0].ProductName}}</h4>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row solidline" *ngIf="Catalog_no!=''">
                                <div class="col-lg-6  col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="Catalog_no!=''">Catlog Num  </h3>
                                    <a href="product-details.component.html"><!--product-details.component.html--></a>
                                </div>

                                <div class="h3 col-lg-6  col-md-6 col-xs-6" *ngIf="Catalog_no!=''">
                                    <b> {{productDetails[0].Catalog_no}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="CasNumber!=''">
                                <div class="col-lg-6  col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="CasNumber!=''">CAS Number</h3>
                                    <a href="product-details.component.html"><!--product-details.component.html--></a>
                                </div>

                                <div class="h3 col-lg-6  col-md-6 col-xs-6" *ngIf="CasNumber!=''">
                                    <b> {{productDetails[0].CasNumber}}</b>
                                </div>
                            </div>
                            <div class="row solidline " *ngIf="OfferingGrades!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="OfferingGrades!=''">Offering Grades </h3>
                                </div>
                                <div class="col-lg-6 h3 col-md-6 col-xs-6" *ngIf="OfferingGrades!=''">
                                    <b>{{productDetails[0].OfferingGrades}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="Botanicalsource!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="Botanicalsource!=''">Plant source  </h3>
                                </div>
                                <div class="h3  col-lg-6 col-md-6 col-xs-6" *ngIf="Botanicalsource!=''">
                                    <b>{{productDetails[0].Botanicalsource}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="Specification!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="Specification!=''">Specification  </h3>
                                </div>
                                <div class="h3   col-lg-6 col-md-6 col-xs-6" *ngIf="Specification!=''">
                                    <b>{{productDetails[0].Specification}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="Standardization!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="Standardization!=''">Standardization  </h3>
                                </div>
                                <div class="h3    col-lg-6 col-md-6 col-xs-6" *ngIf="Standardization!=''">
                                    <b> {{productDetails[0].Standardization}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="Application!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="Application!=''">Application  </h3>
                                </div>
                                <div class="h3   appli col-lg-6 col-md-6 col-xs-6" *ngIf="Application!=''">
                                    <b> {{productDetails[0].Application}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="Trademark!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="Trademark!=''">Trademark  </h3>
                                </div>
                                <div class="h3  col-lg-6 col-md-6 col-xs-6" *ngIf="Trademark!=''">
                                    <b> {{productDetails[0].Trademark}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="IntendedUse!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="IntendedUse!=''">IntendedUse  </h3>
                                </div>
                                <div class="h3   col-lg-6 col-md-6 col-xs-6" *ngIf="IntendedUse!=''">
                                    <b>{{productDetails[0].IntendedUse}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="Description!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont" *ngIf="Description!=''">Description  </h3>
                                </div>
                                <div class="h3  col-lg-6 col-md-6 col-xs-6" *ngIf="Description!=''">
                                    <b>{{productDetails[0].Description}}</b>
                                </div>
                            </div>
                            <div class="row solidline" *ngIf="Name_ofthe_Active_Supplement!=''">
                                <div class="col-lg-6 col-md-6 col-xs-6">
                                    <h3 class="aboutFont breakk" *ngIf="Name_ofthe_Active_Supplement!=''">Name_ofthe_Active_Supplement</h3>
                                </div>
                                <div class="h3   col-lg-6 col-md-6 col-xs-6" *ngIf="Name_ofthe_Active_Supplement!=''">
                                    <b> {{productDetails[0].Name_ofthe_Active_Supplement}}</b>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-xs-2 col-sm-2 whatsss">
                                <img src="../../../assets/img/whatsapp.jpg" (click)="sendWhatsapp(productDetails[0])" class="wts">

                            </div>
                        </div>
                        <br />

                    </div>
                    <div class="functional" *ngIf="FunctionalActivity!=''">
                        Functional Activity
                    </div>
                    <p class="functional" [innerHtml]="FunctionalActivity">
                    </p>
                    <div class="functional" *ngIf="KeyReferences!=''">
                        Key References
                    </div>
                    <p class="functional" [innerHtml]="KeyReferences"></p>
                </div>
                <br />
            </div>
            <div class="col-lg-4 mobileView top">
                <div class="scrollCard">
                    <div class="prod_0">PRODUCT ENQUIRY</div>
                </div>


                <div class="row">
                    <div class="container">
                        <div class="contact-form">


                            <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                                <div class="row">

                                    <div class="form-group mb-3">
                                        <label>Name</label>
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Name is required.</div>
                                            <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                        </div>

                                    </div>

                                    <div class="form-group mb-3">
                                        <label>Email</label>
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>

                                    </div>

                                    <!--<div class="form-group mb-3">
                                        <label>Subject</label>
                                        <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                        <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>

                                    </div>-->

                                    <div class="form-group mb-3">
                                        <label>Phone Number</label>
                                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>

                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <label>Message</label>
                                            <textarea required [(ngModel)]="message" name="message" id="message" cols="30" rows="4" class="form-control">
                                                
                                                 </textarea>
                                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12" style="cursor:pointer">
                                        <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid" style="cursor:pointer;">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                <br />



            </div>


        </div>



    </div>
</div>

<style>
    .custcards {
        border: 1px solid #c1c1c1;
        padding: 11px;
        border-radius: 4px;
        cursor: pointer;
    }

    .cartpad {
        padding-right: 0
    }

    .product_img {
        margin-top: 3%;
        border: 1px solid #c1c1c1;
        padding: 4px;
        border-radius: 6px;
    }
    a:not([href]):not([class]), a:not([href]):not([class]):hover {
        color: white;
        text-decoration: none;
    }
    @media only screen and (max-width:600px){
        .prod_1{
            font-family: arial;
            font-size: 19px;
            font-weight: 700;
            color: #831f41;
            letter-spacing: 0.5px;
            padding: 1rem;
            border-bottom: 1px dashed #831f41;
            margin-bottom: 1rem;
            margin-top: 24px;
        }
        li, a{
            font-size:13px
        }
        .breadcrumb-item {
            font-size: 13px
        }
        .ul {
            font-size: 13px
        }
        .breadcrumb ul li a {
            color: honeydew;
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
        }
        .decCard {
            border: 1px solid #ebebeb;
            padding: 11px;
            border-radius: 5px;
            box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px 0px;
            height: 500px;
        }

    }
</style>


