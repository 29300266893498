import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../Services/generalservice.service';


//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    searchprods: any;
    selectedProduct: any;
    searchedProducts: any;
    arr: any[];
    constructor(private viewportScroller: ViewportScroller,public router: Router, public generalService: GeneralService, public http: HttpClient) {}
    public onClick(elementId: string): void {        
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
       
    }
    /**
filtering products method
 **/
    filterProducts(event) {
        debugger
         let query = event.query;
        if (event.query.length > 2) {
            var UploadFile = new FormData()
            UploadFile.append("Param", query)
            var url = "api/Nutrigene/NU_search"
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger
                this.searchprods = data
            }, err => {
               });
        }
    }
    navigatingToSearchedProduct(data) {
        debugger
        console.log(data);
        var prodName = ""; var prodarr = []
        prodarr.push(data)
        var str = data.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.generalService.setProducts(prodarr)
        this.router.navigate(['/Product/' + prodName]);
    }
    //GetProducts() {
    //    debugger
    //    this.arr = []
    //    this.arr.push({

    //        CatId: '2'

    //    });

    //    var UploadFile = new FormData();
    //    UploadFile.append("Param", JSON.stringify(this.arr));
    //    var url = "api/Nutrigene/Get_NU_CatProducts_basedoncatid";
    //    this.generalService.PostData(url, UploadFile).then(data => {
    //        debugger
    //        this.searchprods = data;
    //        //console.log(data);
    //    }, err => {
    //        // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    //    })
    //}
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    refstand() {
        debugger
        this.router.navigate(['/PhytoChemicalReferenceStandards']);
    }
    leadpyto() {
        this.router.navigate(['/LeadPhotoChemical']);
    }
}
