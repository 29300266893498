//import { Component, OnInit } from '@angular/core';
//import { GeneralService } from '../../Services/generalservice.service';
//import { MessageService } from 'primeng/api';

////import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


//import { Router, ActivatedRoute } from '@angular/router';
//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

//@Component({
//  selector: 'app-phyto-chemical-reference-standards',
//  templateUrl: './phyto-chemical-reference-standards.component.html',
//    styleUrls: ['./phyto-chemical-reference-standards.component.scss'],
//    providers: [MessageService],
//})
//export class PhytoChemicalReferenceStandardsComponent implements OnInit {

//    HomeUrl: any;
//    dataResult: any = [];
//    arr: any[];
//    productList: any = [];
//    ProductName: any;
//    whatsAppProductURL: string;
//    activeroute: any;
//    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public toast: MessageService) {  //
//        this.ProductName = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();
//        this.http.get('../assets/WebService.json').subscribe((data: any) => {
//            this.HomeUrl = data.Webservice;
//        });



//    }
//    //clear() {
//    //    this.toast.clear();
//    //}
//    //addSingle() {
//    //    this.MessageService.add({ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' });
//    //}

//    //addMultiple() {
//    //    this.MessageService.addAll([{ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' },
//    //    { severity: 'info', summary: 'Info Message', detail: 'Via MessageService' }]);
//    //}

//    //clear() {
//    //    this.MessageService.clear();
//    //}

//    ngOnInit(): void {
//        this.GetProducts();
//    }
//    GetProducts() {
//        debugger
//        this.arr = []
//        this.arr.push({

//            CatId: '4',
//           SubCatId:'2'

//        });

//        var UploadFile = new FormData();
//        UploadFile.append("Param", JSON.stringify(this.arr));
//        var url = "api/Nutrigene/Get_NU_CatProducts_basedoncatid";
//        this.generalService.PostData(url, UploadFile).then(data => {
//            // debugger
//            this.dataResult = data;
//            //console.log(data);
//        }, err => {
//            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
//        })
//    }

//    getSelectedProd(product, checked) {
//        var list = this.productList.filter(re => re == product);
//        if (checked && list.length == 0) {
//            this.productList.push(product);
//            this.toast.add({ severity: 'success', summary: 'Success', life: 2000, detail: 'Your enquiry placed successfully ' });

//        } else {
//            this.productList = this.productList.filter(re => re != product);
//            this.toast.add({ severity: 'success', summary: 'Success', life: 2000, detail: 'Your enquiry is deleted  ' });

//        }
//        // console.log(this.productList);
//        localStorage.setItem("product", JSON.stringify(this.productList));
      
//    }





//    navigatingToSelectedProduct(data) {
//        debugger
//        var prodName = ""; var prodarr = []
//        prodarr.push(data)
//        var str = data.ProductName.split(" ")//spliting the category name into string array where space occured
//        for (let i = 0; i < str.length; i++) {
//            prodName += "_" + str[i];
//        }
//        this.generalService.setProducts(prodarr)
//        this.router.navigate(['/Product/' + prodName]);
//    }

//    sendWhatsapp(item) {
//        debugger
//        var prodName = " "
//        var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
//        for (let i = 0; i < str.length; i++) {
//            prodName += "_" + str[i];
//        }
//        this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/" + 'PhytoChemicalReferenceStandards' + prodName + '/' + item.CasNumber
//        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
//    }



//}


import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { MessageService } from 'primeng/api';

//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
    selector: 'app-phyto-chemical-reference-standards',
    templateUrl: './phyto-chemical-reference-standards.component.html',
    styleUrls: ['./phyto-chemical-reference-standards.component.scss'],
    providers: [MessageService],
})
export class PhytoChemicalReferenceStandardsComponent implements OnInit {

    HomeUrl: any;
    dataResult: any = [];
    arr: any[];
    productList: any = [];
    whatsAppProductURL: string;
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public toast: MessageService) {  //
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });



    }
    //clear() {
    //    this.toast.clear();
    //}
    //addSingle() {
    //    this.MessageService.add({ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' });
    //}

    //addMultiple() {
    //    this.MessageService.addAll([{ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' },
    //    { severity: 'info', summary: 'Info Message', detail: 'Via MessageService' }]);
    //}

    //clear() {
    //    this.MessageService.clear();
    //}

    ngOnInit(): void {
        this.GetProducts();
    }
    GetProducts() {
        debugger
        this.arr = []
        this.arr.push({

            CatId: '4',
            SubCatId: '2'

        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/Nutrigene/Get_NU_CatProducts_basedoncatid";
        this.generalService.PostData(url, UploadFile).then(data => {
            // debugger
            this.dataResult = data;
            //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }

    getSelectedProd(product, checked) {
        var list = this.productList.filter(re => re == product);
        if (checked && list.length == 0) {
            this.productList.push(product);
            this.toast.add({ severity: 'success', summary: 'Success', life: 2000, detail: 'Your enquiry placed successfully ' });

        } else {
            this.productList = this.productList.filter(re => re != product);
            this.toast.add({ severity: 'success', summary: 'Success', life: 2000, detail: 'Your enquiry is deleted  ' });

        }
        // console.log(this.productList);
        localStorage.setItem("product", JSON.stringify(this.productList));

    }


    navigatingToSelectedProduct(data) {
        debugger
        var prodName = ""; var prodarr = []
        prodarr.push(data)
        var str = data.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.generalService.setProducts(prodarr)
        this.router.navigate(['/Product/' + prodName]);
    }

    sendWhatsapp(item) {
        debugger
        var prodName = " "
        var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/" + 'PhytoChemicalReferenceStandards' + prodName + '/' + item.CasNumber + this.dataResult.ProductImage

        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }



    //sendWhatsapp(item: any) {
    //    const prodName = item.ProductName.replace(/ /g, '_'); // Replace spaces with underscores

    //    // Assuming you have a dynamic productId
    //    const productId = item.ProductId; // Replace with your actual productId source

    //    // Use the ImageService to fetch the dynamic image URL
    //    this.dataResult.getImageURL(productId).subscribe(
    //        (productImageURL: string) => {
    //            //const encodedProductImageURL = encodeURIComponent("{{HomeUrl+item.ProductImage}}");
    //            //const whatsAppProductURL = `https://Nutrigene.gagriglobal.com/%23/PhytoChemicalReferenceStandards${prodName}/${item.CasNumber}?image=${encodedProductImageURL}`;
    //            const message = `Check out this product: ${this.whatsAppProductURL}\nImage: ${productImageURL}`;
    //            // Open the WhatsApp message URL
    //            //window.open(`https://api.whatsapp.com/send?&text=${whatsAppProductURL}`);
    //            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`);
    //        },
    //        (error: any) => {
    //            console.error('Error fetching image:', error);
    //        }
    //    );
    //}


    //sendWhatsapp(item: any) {
    //    // Construct the WhatsApp message with link, content, and image
    //    const shareMessage = `Check out this product: ${window.location.href}\n${item.ProductName}`;
    //    const imageUrl = `${this.HomeUrl}${item.ProductImage}`;

    //    // Create a temporary element to hold the image
    //    const img = document.createElement('img');
    //    img.src = imageUrl;
    //    img.style.display = 'none';

    //    // Add the image to the document
    //    document.body.appendChild(img);

    //    // Wait for the image to load before sharing
    //    img.onload = () => {
    //        // Open the WhatsApp message URL with the image included
    //        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(shareMessage)}`);

    //        // Remove the temporary image element
    //        document.body.removeChild(img);
    //    };
    //}
}


