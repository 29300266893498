import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bakuchiol',
  templateUrl: './bakuchiol.component.html',
  styleUrls: ['./bakuchiol.component.scss']
})
export class BakuchiolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
