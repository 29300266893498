<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
            <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Gallery</b></li>
            <div>Gallery</div>

        </ul>
    </div>
</nav>
<!--<div class="main-banner">
    <div class="d-table">
        <div>

            <img src="../../../assets/img/Gallery.PNG" style="width:100%" />

        </div>
    </div>
</div>-->

<div id="blog" class="blog-area ptb-100">
    <div class="container">

     <!--<ng-image-slider #nav
                     [images]="imgCollection"
                     [infinite]="false"
                     [autoSlide]="1"
                     [imageSize]=""
                     slideImage="1" ></ng-image-slider>-->
    <!-- Image Gallery with Modal Trigger -->
        <!-- HTML template -->
        <!-- Image Gallery -->
        <div class="row"  (click)="openModal(imgCollection,1)" >
        <div class="col-lg-3 col-xs-6" *ngFor="let image of imgCollection">
            <div class="image-container" >
                <a href="{{ image.image }}" target="_blank"><img src="{{ image.image }}" /></a>
            </div>
        </div>
    </div>
        <!-- Image Gallery -->
        <!-- Image Gallery -->
        <!--<div class="row">
            <div class="col-lg-3 col-xs-6" *ngFor="let image of imgCollection">
                <div class="image-container" (click)="openModal(image)">
                    <a href="#"><img src="{{ image.image }}" /></a>
                </div>
            </div>
        </div>


        <div *ngIf="imageSliderVisible" class="modal">
            <div class="image-slider">
                <ng-image-slider #nav
                                 [images]="imgCollection"
                                 [infinite]="false"
                                 [autoSlide]="1"
                                 [imageSize]=""
                                 slideImage="1">
                </ng-image-slider>
                <button (click)="closeModal()">Close</button>
            </div>
        </div>-->


        <!-- Image Slider -->
        <!--<div *ngIf="imageSliderVisible" class="image-slider">
        <button (click)="prevImage()">Previous</button>
        <img [src]="imgCollection" />
        <button (click)="nextImage()">Next</button>
        <button (click)="closeModal()">Close</button>
    </div>-->
        <!-- Image Modal -->
        <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true" [ngClass]="{ 'show': modalVisible }">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="btn btn-primary" (click)="prevImage()" id="prevBtn">Previous</button>
                        <img id="modalImage" class="img-responsive" [src]="selectedImage">
                        <button type="button" class="btn btn-primary" (click)="nextImage()" id="nextBtn">Next</button>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>



















    </div>
    </div>
    <script>
    $(document).ready(function () {
            $('#lightgallery').lightGallery();
        });</script>
    <script>
        // Function to get the current image index from the URL
        function getCurrentIndexFromURL() {
            const params = new URLSearchParams(window.location.search);
            return parseInt(params.get('index')) || 0; // Default to 0 if index is not provided
        }

        // Function to navigate to the next image
        function nextImage() {
            const currentIndex = getCurrentIndexFromURL(); // Get the current index
            const nextIndex = currentIndex + 1; // Calculate the index of the next image
            window.opener.nextImage(nextIndex); // Call the nextImage function in the parent window
            window.close(); // Close the current tab
        }

        // Function to navigate to the previous image
        function prevImage() {
            const currentIndex = getCurrentIndexFromURL(); // Get the current index
            const prevIndex = currentIndex - 1; // Calculate the index of the previous image
            window.opener.prevImage(prevIndex); // Call the prevImage function in the parent window
            window.close(); // Close the current tab
        }
    </script>
