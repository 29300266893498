import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';


import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
@Component({
    selector: 'app-news-and-events',
    templateUrl: './news-and-events.component.html',
    styleUrls: ['./news-and-events.component.scss']
})
export class NewsAndEventsComponent implements OnInit {
    dataResult: any;
    dataResult1: any;
    HomeUrl: any;
    dataResultMain: any;
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) { 
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
          this.HomeUrl = data.Webservice;
      
        });
    }
    ngOnInit() {
        this.GetNews_Events();
        this.GetEvents();
    }
    GetNews_Events() {
        var url = "api/Nutrigene/Get_cust_news";
        this.generalService.GetData(url).then(data => {
            this.dataResult = data;
            //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    GetEvents() {
        var url = "api/Nutrigene/Get_cust_Events";
        this.generalService.GetData(url).then(data => {
            this.dataResult1 = data;
            debugger;
            this.dataResultMain = this.dataResult1.reduce((accumalator, current) => {
                if (!accumalator.some((item) => item.EventsID == current.EventsID)) {
                    accumalator.push(current);
                }
                return accumalator;
            }, []);
            //console.log(this.dataResult1);
            //console.log(this.dataResultMain);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    rating(el: HTMLElement) {

        el.scrollIntoView();
    }
}
