import { Component, OnInit , Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormsModule } from '@angular/forms'

import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    JobID: any;
    contactForm: FormsModule;
    SubCatId:any;
    productDetails: {};
    ProductName:any;ProductName111:any;
    Catalog_no: any;
    CasNumber: any;CasNumber111: any;
    Description: any;
    ProductImage: any;
    Botanicalsource: any;
    CommonName: any;
    Name_ofthe_Active_Supplement: any;
    Specification: any;
    Structure: any;
    OfferingGrades: any;
    Application: any;
    Trademark: any;
    FunctionalActivity: any;
    KeyReferences: any;
    IntendedUse: any;
    Standardization: any;
    CategoryName: any;
    SubCategoryName: any;
    whatsAppProductURL: string;
    HomeURL: any;
    CatId: any;
    catName: string;
    message: any;
    activeroute: any;
    Tests: any[];
  
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public fb: FormsModule, public route: ActivatedRoute, private cookieService: CookieService) {
        debugger
       
        this.CasNumber111 = this.route.snapshot.paramMap.get("CasNumber");
       
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
                      
            this.HomeURL = data.Webservice;
            debugger
            this.ProductName = this.route.snapshot.paramMap.get('ProductDeatails').replace(/\_/g, " ").trim();
            this.message = this.ProductName 
            this.generalService.GetProducts().subscribe(res => {
                if (res.length == 0) {//if category id is null
                    this.GetProductDetailsbasedonProductName()
                }
                else {
                    this.ProductName = res[0].ProductName;
                    this.GetProductDetailsbasedonProductName()
                  
                }
            })
        });
    }
    ngOnInit(): void {
       
  }
    GetProductDetailsbasedonProductName() {
        debugger
        var UploadFile = new FormData()
        UploadFile.append("Param", this.ProductName)
        var url = "/api/Nutrigene/Get_Products_basedon_ProductName"
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.productDetails = data;
            this.JobID = (this.productDetails[0].JobID);
            this.ProductName = (this.productDetails[0].ProductName);
            this.Catalog_no = (this.productDetails[0].Catalog_no);
            this.CasNumber = (this.productDetails[0].CasNumber);
            this.Description = (this.productDetails[0].Description);
            this.ProductImage = (this.productDetails[0].ProductImage);
            this.Botanicalsource = (this.productDetails[0].Botanicalsource);
            this.CommonName = (this.productDetails[0].CommonName);         
            this.Name_ofthe_Active_Supplement = (this.productDetails[0].Name_ofthe_Active_Supplement);
            this.Specification = (this.productDetails[0].Specification);     
            this.Structure = (this.productDetails[0].Structure);
            this.Application = (this.productDetails[0].Application);
            this.OfferingGrades = (this.productDetails[0].OfferingGrades);
            this.Trademark = (this.productDetails[0].Trademark);          
            this.Standardization = (this.productDetails[0].Standardization);
            this.IntendedUse = (this.productDetails[0].IntendedUse);
            this.SubCategoryName = (this.productDetails[0].SubCategoryName);
            this.CategoryName = (this.productDetails[0].CategoryName);
            this.FunctionalActivity = decodeURIComponent(this.productDetails[0].FunctionalActivity);
            this.KeyReferences = decodeURIComponent(this.productDetails[0].KeyReferences);
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }

    navigatingtoFarmerProduces(data) {
        var catName = "";
        var str = data.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            catName += "_" + str[i];
        }
        this.router.navigate(['/ProductDetails/' + catName]);

    }
 
  
    sendWhatsapp(item) {
        var prodName = ""
        var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/Product/" + prodName
        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }
    onSubmit(form: any) {
        debugger;
        this.Tests = [];
        this.Tests.push({
            Products: this.productDetails[0].ProductName,
            Catalog_no: this.productDetails[0].Catalog_no,
            CasNumber: this.productDetails[0].CasNumber,
            Description: this.productDetails[0].Description
          
        });


        this.EnquiryAry = []; this.EnquiryAry.push({
            name: form.name, number: form.number,
            email: form.email, message: form.message
        })
        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
        UploadFile.append("Ticket2", JSON.stringify(this.Tests));
        var url = "/api/Nutrigene/RequestMailToAdmin"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;


            Swal.fire(
                'SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success'

            )
            window.location.reload();
        });

    }

    //onSubmit(form: any) {
    //    debugger;
    //    localStorage.setItem("userDetails", JSON.stringify(this.userDetails));
    //    this.Tests = [];
       
    //        this.Tests.push({
    //            Products :this.productDetails[0].ProductName,
    //           Catalog_no :this.productDetails[0].Catalog_no,
    //           CasNumber:this.productDetails[0].CasNumber,
    //           Description:this.productDetails[0].Description
    //        });
        
        
    //    console.log(this.userDetails);
    //    localStorage.setItem("userDetails", JSON.stringify(this.userDetails));
        
    //    this.EnquiryAry = []; this.EnquiryAry.push({
    //        name: this.userDetails.Name, number: this.userDetails.Number,
    //        email: this.userDetails.Email, message: this.userDetails.Message
    //    })
    //    var UploadFile = new FormData();
    //    UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
    //    UploadFile.append("Ticket2", JSON.stringify(this.Tests));
    //    var url = "/api/Nutrigene/RequestMailToAdmin"
    //    this.generalService.PostData(url, UploadFile).then((data: any) => {
    //        debugger;

          
    //        Swal.fire(
    //            'SUCCESS',
    //            'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
    //            'success'

    //        )
    //        window.location.reload();
    //    });

    //}

    
    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var message = form.message;
        console.log(message);
    }
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }

}
