import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';

//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';



import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-lead-photo-chemical',
    templateUrl: './lead-photo-chemical.component.html',
    styleUrls: ['./lead-photo-chemical.component.scss']
})
export class LeadPhotoChemicalComponent implements OnInit {
    KeyReferences: any;
    FunctionalActivity: any;
    whatsAppProductURL: string;
    ProductName: any;
    HomeUrl: any;
    dataResult: any = [];
    arr: any[];
    addNew: boolean;
    dataResult1: any = [];
    ProductID: any;
    Tests: any[];
    EnquiryAry: any[];
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient, public activeroute: ActivatedRoute) {  //
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            this.ProductName = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();
        });



    }

    ngOnInit(): void {
        this.GetProducts();
        // this.Getsub_descrandRef();
    }
    GetProducts() {
        debugger
        this.arr = []
        this.arr.push({

            CatId: '4',
            SubCatId: '1'

        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/Nutrigene/Get_NU_CatProducts_basedoncatid";
        this.generalService.PostData(url, UploadFile).then(data => {
            // debugger
            this.dataResult = data;
            this.addNew = true;

            for (var i = 0; i < this.dataResult.length; i++) {
                this.dataResult[i].KeyReferences = decodeURIComponent(this.dataResult[i].KeyReferences);
                this.dataResult[i].FunctionalActivity = decodeURIComponent(this.dataResult[i].FunctionalActivity);

            }
            //console.log(data);
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
    //subcardes() {
    //    this.addNew = true;

    //}
    onSubmit(form: any) {
        debugger;
        this.Tests = [];

        this.Tests.push({
            Products: this.dataResult[0].ProductName,
            Catalog_no: this.dataResult[0].Catalog_no,
            CasNumber: this.dataResult[0].CasNumber,
            Description: this.dataResult[0].Description
        });


        this.EnquiryAry = []; this.EnquiryAry.push({ name: form.name, number: form.number, email: form.email, message: form.message })
        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
        UploadFile.append("Ticket2", JSON.stringify(this.Tests));
        var url = "/api/Nutrigene/RequestMailToAdmin"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger;


            Swal.fire(
                'SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success'

            )
            window.location.reload();
        });

    }

    /*navigating to customer selected products from our products*/

    navigatingToSelectedProduct(data) {
        debugger
        var prodName = ""; var prodarr = []
        prodarr.push(data)
        var str = data.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.generalService.setProducts(prodarr)
        this.router.navigate(['/Product/' + prodName]);
    }



    sendWhatsapp(item) {
        debugger
        var prodName = " "
        var str = item.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.whatsAppProductURL = "https://Nutrigene.gagriglobal.com/%23/" + 'LeadPhytoChemical' + prodName + '/' + item.CasNumber + '/' + item.Specification


        window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
    }



    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var message = form.message;
        console.log(message);
    }

    getSubCat(ProductID: any) {
        this.ProductID = ProductID;
    }
}
