<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="..\..\assets\img\NutrigeneLogo.png" class="logo" />
        </a>
        <button class="navbar-toggler" type="button">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link"><a routerLink="/Home">Home</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/about-us">About</a></span></li>                
                <li class="nav-item"><span class="nav-link"><a routerLink="/active-botanical-pharma-ingredients">Active Botanical Pharma Ingredients</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/standardized-botanical-supplements">Standardized Botanical Supplements</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/novel-standard-extracts">Novel Standard Extracts</a></span></li>
                <!--<li class="nav-item"><span class="nav-link"><a routerLink="/novel-standard-extracts">Phyto Chemical Standards</a></span></li>-->
                <li class="nav-item dropdown">
                    <span class="nav-link dropbtn">
                        <a routerLink="/phyto-chemical-reference-standards"> Phyto Chemicals </a>
                        <i class="fa fa-caret-down"></i>
                    </span>
                    <div class="dropdown-content">
                        <a routerLink="/phyto-chemical-reference-standards">Reference Standards</a>
                        <a routerLink="/lead-photo-chemical">Lead Chemicals</a>
                    </div>
                </li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/contact-us">Contact</a></span></li>
                <li class="nav-item"><span class="nav-link"><a routerLink="/login">Login</a></span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" >Home</span></li>
                <li class="nav-item"><span class="nav-link" >About</span></li>
                <li class="nav-item"><span class="nav-link" >Active Botanical Pharma Ingredients</span></li>
                <li class="nav-item"><span class="nav-link" >Standardized Botanical Supplements</span></li>
                <li class="nav-item"><span class="nav-link" >Novel Standard Extracts</span></li>
                <li class="nav-item"><span class="nav-link" >Phyto Chemical Standards</span></li>
                <li class="nav-item"><span class="nav-link" >Contact</span></li>
                <li class="nav-item"><span class="nav-link"><a href="login">Login</a></span></li>
            </ul>
        </div>
    </div>
</nav>
