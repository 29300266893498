<div class="main-banner">
    <div class="d-table">
        <div>
            <img src="../../../assets/img/Banner1_1.png" style="width:100%" />
            <div class="center">
                <h3 class="bro">Brochurs</h3>
                <b  class="nunt">Nutrigene</b>

            </div>
        </div>
    </div>
</div>
<div class="container" style="margin-top:20px">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home"><b>Home</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b> Brochure </b></li>
        </ol>
    </nav>

    <div class="row mobileMargin mb-3" style="margin:0 -22px;">
        <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6" *ngFor="let item of dataResult">
            <div>

              

                <a href="{{HomeUrl+item.BrochurPath}}" target="_blank"style="cursor:pointer">
                    <img  class="card_image col-xs-6" alt="Nutrigene" src="../../../assets/img/pdf.jpg" /></a>
                    <h4 class="bron">{{item.BrochurName}} </h4>


            </div>
        </div>
    </div>


</div>
