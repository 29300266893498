<div class="demo-modal-panel">
    <button class="sidebar-demo-control" (click)="toggleClass()">
        <span>Enquiry</span>
    </button>
</div>

<div class="example-demo-modal" [class.active]="classApplied">
    <br /> <br />
    <div class="contact-form" style="height:580px;overflow-y:auto;">
        <form #contactForm="ngForm">
            <div class="form-group mb-3">
                <label>Name</label>
                <input required minlength="3" maxlength="50" [(ngModel)]="userDetails.Name" name="name" type="text" class="form-control" id="name">
                <!--<div class="alert alert-danger" *ngIf="name.touched && !name.valid">#name="ngModel"
                    <div *ngIf="name.errors.required">Name is required.</div>
                    <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                    <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                </div>-->
            </div>
            <div class="form-group mb-3">
                <label>Email</label>
                <input required [(ngModel)]="userDetails.Email" name="email" type="text" class="form-control" id="email">
                <!--<div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div> #email="ngModel"-->
            </div>
            <div class="form-group mb-3">
                <label>Phone Number</label>
                <input required [(ngModel)]="userDetails.Number" name="number" type="text" class="form-control" id="number">
                <!--<div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div> #number="ngModel"-->
            </div>
            <div class="form-group mb-3">
                <label>Description</label>
                <textarea required [(ngModel)]="userDetails.Message" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                <!--<div class="alert alert-danger" *ngIf="message.touched && !name.valid">Description is required.</div> #message="ngModel"-->
            </div>
            <div class="form-group mb-3">
                <div *ngFor="let item of productsList;let i=index;">
                    <label>{{i+1}}.{{item}}</label>
                    <div *ngIf="item!=null">
                        <i class="fa fa-times m-delete-img" (click)="removeImage(item)"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <button type="submit" class="btn btn-primary" [class.disabled]="!userDetails.Name && !userDetails.Email && !userDetails.Number && !userDetails.Message" (click)="onSubmit()">Send Message</button>
            </div>
            <!--<div>
                <input type="checkbox" [checked]="Remember" (change)="RememberMe($event)" class="custom-control-input" id="login-checkbox">
                <label class="custom-control-label" for="login-checkbox">Keep me my data.</label>
            </div>-->
        </form>
    </div>
    <div class="header-title">
        <button class="example-demo-modal-control" (click)="toggleClass()">
            <i class="fas fa-times"></i>
        </button>
        <div class="title">Enquiry</div>
    </div>
</div>


