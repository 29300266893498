import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
//import { FormsModule } from '@angular/forms'
//import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralService } from '../../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-enquiry',
    templateUrl: './enquiry.component.html',
    styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {

    EnquiryAry: any = [];
    public form: FormGroup;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    cookieEmailValue: any;
    cookiePWDValue: any;
    Remember: boolean;
    CheckBox: string;
    cookienameValue: any;
    productsList: any = [];
    Tests: any = [];
    userDetails = { Name: "", Email: "", Number: "", Message: "" };
    router: any;
    constructor(private viewportScroller: ViewportScroller, public generalService: GeneralService, public fb: FormsModule, public Rm: ReactiveFormsModule, private cookieService: CookieService) {
        //localStorage.removeItem("LoginDetails");
        //this.router = router;
        //this.form = fb.group({
        //    'email': ['', Validators.compose([Validators.required, EmailValidators.normal])],
        //    'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
        //});

        //this.email = this.form.controls['email'];
        //this.password = this.form.controls['password'];

   


    }
    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);

    }

    RememberMe(ev) {
        debugger
        if (ev.target.checked == true) {
            this.Remember = true;
            this.cookieService.set('name', this.form.value.name);
            this.cookieService.set('number', this.form.value.number);
            this.cookieService.set('email', this.form.value.email);
         
            this.cookieService.set('RememberMe', 'Checked');
            this.CheckBox = 'Checked'
        }
        else if (ev.target.checked == false) {
            // this.cookieService.set('RememberMe', 'UnChecked');
            this.CheckBox = ''
            this.Remember = false;
            this.cookieService.deleteAll();

        }
    }
    ngOnInit(): void {

        const cookieExists: boolean = this.cookieService.check('RememberMe');
        //if (cookieExists) {
        //    this.cookienameValue = this.cookieService.get('name');
        //    this.cookiePWDValue = this.cookieService.get('number');
        //    this.cookieEmailValue = this.cookieService.get('email');
          
        
        //    this.form.controls.name.setValue(this.cookienameValue)
        //       this.form.controls.password.setValue(this.cookiePWDValue)
        //    this.form.controls.email.setValue(this.cookieEmailValue)
         
          
        //    this.Remember = true;

        //} else {
        //    this.form.reset();
        //}
        if (JSON.parse(localStorage.getItem("userDetails"))) {
            this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
        } else {
            this.userDetails = { Name: "", Email: "", Number: "", Message: "" };
        }
    }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
        this.productsList = JSON.parse(localStorage.getItem("product"));
        if (JSON.parse(localStorage.getItem("userDetails"))) {
            this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
        } else {
            this.userDetails = { Name: "", Email: "", Number: "", Message: "" };
        }
    }
    /*remove the multiple images*/
    removeImage(product) {    /////Remove Image
        debugger
        //this.productsList = '';
        this.productsList = this.productsList.filter(re => re != product);
        localStorage.setItem("product", JSON.stringify(this.productsList));
    }
    onSubmit() {
        this.Tests = [];
        for (var i = 0; i < this.productsList.length; i++) {
            this.Tests.push({
                Products:this.productsList[i]
            });
        }
        console.log(this.userDetails);
        localStorage.setItem("userDetails", JSON.stringify(this.userDetails));
       // this.productsList = this.productsList
        this.EnquiryAry = []; this.EnquiryAry.push({
            name: this.userDetails.Name, number: this.userDetails.Number,
            email: this.userDetails.Email, message: this.userDetails.Message
        })
        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
        UploadFile.append("Ticket2", JSON.stringify(this.Tests));
        var url = "/api/Nutrigene/EnquiryMailToAdmin1"
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.contactForm.reset();
            Swal.fire(
                'SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success'
            )
            this.productsList = [];
            localStorage.setItem("product", JSON.stringify(this.productsList));
           
            // this.sendsms();
        });

    }
}
