<!DOCTYPE html>
<html lang="en">
<head>
    <!-- Other meta tags and head content -->
    <!--<app-og-meta [imageUrl]="HomeURL + productDetails[0].ProductImage"
                 [title]="productDetails[0].Title"
                 [description]="productDetails[0].Description"
                 [url]="HomeURL + 'your-page'"></app-og-meta>-->

    <!-- Include your CSS and other head content here -->
</head>
<body>
    <!-- Your page content -->

    <!--<img src="{{ HomeURL + productDetails[0].ProductImage }}" class="img_0" alt="Nutrigene" *ngIf="productDetails[0].ProductImage">-->

    <!-- Include your JavaScript and body content here -->
</body>
</html>

