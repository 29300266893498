import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'app-og-meta',
  templateUrl: './og-meta.component.html',
  styleUrls: ['./og-meta.component.scss']
})
export class OgMetaComponent implements OnInit {
//imageUrl: string;
//title: string;
//description: string;
//url: string;


  constructor() { }

  ngOnInit(): void {
  }

}
