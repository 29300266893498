<!--<div class="main-banner">
    <div class="d-table">
        <div>

            <img src="../../../assets/img/Banner3_3.PNG" class="torine-img" alt="nutrigene"/>
            <div class="center2">
                <h1 class="pelli">Pellitorine </h1>
                <h3 class="pelli_1">
                    A potential anti-cancer lead compound against HL60 <br /> and MCF-7
                    cell lines
                </h3>
            </div>
        </div>
    </div>
</div>--><nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
            <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Products</b></li>
            <div> Standardized Botanical Supplements</div>

        </ul>
    </div>
</nav>

         <!--<div class="row">

             <div class="col-lg-3">
                 <img src="assets/img/compressedimages/DSC00332rebg.png" />
             </div>
             <div class="col-lg-3">
                 <img src="assets/img/compressedimages/DSC00322-rebg.png" />
             </div>
             <div class="col-lg-3">
                 <img src="assets/img/compressedimages/DSC00329rebg.png" />
             </div>
             <div class="col-lg-3">
                 <img src="assets/img/compressedimages/DSC00327-rebg.png" />
             </div>

         </div>-->
<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-2 col-xs-6" (click)="rating1(ratingsec1)">
                <div class="card">
                    <div (click)="getCat1()" id="click" style="cursor:pointer;">
                        <img src="assets/img/product.png" class="card_image col-xs-6" alt="Nutrigene" style="cursor:pointer;" />
                        <h5 class="card-titlle title"><b>All Products</b></h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-2 col-xs-6" *ngFor="let item of dataResult" (click)="rating(ratingsec)">
                <div class="card">
                    <div (click)="getSubCat(item.SubCatId)" id="click" style="cursor:pointer;">
                        <img src="{{HomeUrl+item.SubcatImage}}" class="card_image col-xs-6" alt="Nutrigene" style="cursor:pointer;">
                        <h5 class="card-titlle title"><b>{{item.SubCategoryName}}</b></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<hr style="width:80%;margin-left:8%" />-->
    <div class="category" #ratingsec>
        <div class="container" style="margin-top:20px">

            <div class="row mobileMargin mb-3">
                <div *ngFor="let item of dataResult">
                    <div *ngIf="item.SubCatId==SubCatID">
                        <hr />
                        <h4 style="font-size: 36px;"><b><span style=" color:#14818f">{{item.SubCategoryName}}</span></b></h4><br />
                        <p style="color:#4b4c50;font-size:13px">{{item.Description}}</p><br />
                        <table id="customers" style="overflow-wrap: anywhere;">
                            <tr style="font-size:18px">
                                <th>Product Name</th>
                                <th>Active Supplement</th>
                                <th>CAS&nbsp;Number</th>
                                <th>Grades</th>
                                <th>Botanical Source</th>
                            </tr>
                            <tr *ngFor="let pro of item.products">
                                <td> {{pro.ProductName}}</td>
                                <td> {{pro.Name_ofthe_Active_Supplement}}</td>
                                <td>{{pro.CasNumber}}</td>
                                <td>{{pro.OfferingGrades}}</td>
                                <td>{{pro.Botanicalsource}}</td>
                            </tr>
                        </table>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="category" #ratingsec1>
        <div class="container" style="margin-top:20px" *ngIf="dataResult11==''&& allview==false">
            <div class="row mobileMargin mb-3">


                <hr />
                <h4 style="font-family: Poppins!important; font-size: 16px;"><b><span style=" color: #14818f; ">All Products</span></b></h4><br />
                <table id="customers" style="overflow-wrap: anywhere;">
                    <tr style="font-size:18px">
                        <th>Product Name</th>
                        <th>Active Supplement</th>
                        <th>CAS Number</th>
                        <th>Grades</th>
                        <th>Botanical Source</th>
                    </tr>
                    <tr *ngFor="let pro of dataResult11">
                        <td> {{pro.ProductName}}</td>
                        <td> {{pro.Name_ofthe_Active_Supplement}}</td>
                        <td>{{pro.CasNumber}}</td>
                        <td>{{pro.OfferingGrades}}</td>
                        <td>{{pro.Botanicalsource}}</td>
                    </tr>
                </table>
                <br />


            </div>
        </div>

        <div class="container" style="margin-top:20px" *ngIf="allview==true">
            <div class="row mobileMargin mb-3">


                <hr />
                <tr>
                    <td> <h4 style="font-family: Poppins sans-serif !important; font-size: 26px; "><b><span style=" color:#14818f">All Products</span></b></h4> </td>
                    <!--<td style="padding-left:200%">
                        <p-autoComplete placeholder=" Search Product ..!" [(ngModel)]="selectedproducts" [suggestions]="searchcomp" (completeMethod)="filterTests()" field="ProductName" [dropdown]="false" class="lab" (onClear)="clearItem($event)">
                        </p-autoComplete>
                    </td>-->
                </tr>

                <table id="customers" style="overflow-wrap: anywhere;">
                    <tr style="font-size:18px">
                        <th>Product Name</th>
                        <th>Active Supplement</th>
                        <th>CAS Number</th>
                        <th>Grades</th>
                        <th>Botanical Source</th>
                    </tr>
                    <tr *ngFor="let pro of dataResult11">
                        <td> {{pro.ProductName}}</td>
                        <td> {{pro.Name_ofthe_Active_Supplement}}</td>
                        <td>{{pro.CasNumber}}</td>
                        <td>{{pro.OfferingGrades}}</td>
                        <td>{{pro.Botanicalsource}}</td>
                    </tr>
                </table>
                <br />


            </div>
        </div>
    </div>

    </div>
