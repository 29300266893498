<div id="welcome" class="welcome-area pt-100 pb-70 wel_0">
    <div class="container">
        <div class="section-title">
            <!--<h4>We are creative</h4>-->
            <h2>Our <span>Categories</span></h2>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/ActiveBotanicalPharmaIngredients" title="Read More" class="link-btn">
                    <div class="single-box">
                        <div class="icon">
                            <img src="../../../../assets/img/Nutrigene/botanical.webp" style="border-radius:5px" />
                            <!--<img src="../../../../assets/img/BDRlogo.jpeg" />-->
                        </div>
                        <h3>Novel standards extract</h3>
                        <p>A Novel standards extract is defined as any substance that is a food or part of a food and provides medical or health benefits, including the prevention and treatment of disease.</p>
                        <a routerLink="/ActiveBotanicalPharmaIngredients" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div></a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/NovelStandardExtracts" title="Read More" class="link-btn">
                    <div class="single-box">
                        <div class="icon">
                            <img src="../../../../assets/img/Nutrigene/nutricals.webp" style="border-radius:5px" />
                            <!--<img src="../../../../assets/img/BDRlogo.jpeg" />-->
                        </div>
                       

                        <h3>Botanical API's</h3>
                        <p>Active Botanical is a plant, root, seed, or fruit with rejuvenation properties, often providing antioxidant, anti-inflammatory, and restorative properties to the skin and body.</p>

                        <a routerLink="/NovelStandardExtracts" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div></a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/StandardizedBotanicalSupplements" title="Read More" class="link-btn">
                    <div class="single-box">
                        <div class="icon">
                            <img src="../../../../assets/img/Nutrigene/dietary.webp"style="border-radius:5px"  />
                            <!--<img src="../../../../assets/img/BDRlogo.jpeg" />-->
                        </div>
                        <h3>Dietary Supplements</h3>
                        <p>A dietary supplement is a manufactured product intended to supplement one's diet by taking a pill, capsule, tablet, powder, or liquid.</p>
                        <a routerLink="/StandardizedBotanicalSupplements" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div></a>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/PhytoChemicalReferenceStandards" title="Read More" class="link-btn">
                    <div class="single-box">
                        <div class="icon">
                            <img src="../../../../assets/img/Nutrigene/refenece.webp"style="border-radius:5px"  />
                            <!--<img src="../../../../assets/img/BDRlogo.jpeg" />-->
                        </div>
                        <h3>Reference Standards</h3>
                        <p>The reference standard is the test, combination of tests, or procedure that is considered the best available method of categorising participants in a study of diagnostic test accuracy as having or not having a target condition.</p>
                        <a routerLink="/PhytoChemicalReferenceStandards" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div></a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/LeadPhytoChemical" title="Read More" class="link-btn">
                    <div class="single-box">
                        <div class="icon">
                            <img src="../../../../assets/img/Nutrigene/phyto.webp" style="border-radius:5px" />
                            <!--<img src="../../../../assets/img/BDRlogo.jpeg" />-->
                        </div>
                        <h3>PhytoChemicals</h3>
                        <p>Phytochemicals are defined as bioactive nutrient plant chemicals in fruits, vegetables, grains, and other plant foods that may provide desirable health benefits beyond basic nutrition to reduce the risk of major chronic diseases.</p>
                        <a routerLink="/LeadPhytoChemical" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    </div></a>
            </div>
        </div>
    </div>
    <!--<div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>-->
</div>
