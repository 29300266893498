<!--<img src="../../../../assets/img/compressedimages/DSC00416.webp" alt="Banner Image"style="width:100%;height:520px;object-fit:fill" >-->
<!--<img src="../../../../assets/img/compressedimages/DSC00416.webp" alt="Banner Image" class="banner_img" />-->
<img src="../../../../assets/img/Nutrigene/bg.png"style="width:100%"class="bg_nutrigene" />
<!--<div class="container">
    <div class="col-lg-12">

        <div class="row wor_0">
            <div class="col-lg-4">
                <div class=" box">
                    <img src="../../../../assets/img/Nutrigene/why.jpg" class="abou_img" />
                    <p>Our core values are driven by Curiosity, Ambition & Humility in equal parts, Agility & Perseverance in doing whatever it takes to live up to our Commitments, and a Shared, Collaborative Accountability.</p>

                </div>

            </div>
            <div class="col-lg-4">
                <div class=" box">
                    <img src="../../../../assets/img/Nutrigene/whatwedo.png" class="abou_img" />
                    <p>
                        Our primary  focus  is  on  providing High Value Natural Ingredients, Algal Omega acids (EPA ,DHA and blends), CoQ10, Bakuchiol & Reference Standards to the Nutraceutical, Food and Cosmeceutical industry.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class=" box">
                    <img src="../../../../assets/img/Nutrigene/expeer.png" class="abou_img" />
                    <p>We bring our track record and expertise in R&D, Technology Development, Scale-Up, large scale Industrial Fermentation and Botanical Extraction with solid, unparalleled customer relationships.</p>
                </div>
            </div>
        </div>

    </div>
</div>--><br />
<!--<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="card card-1">
                <h3>Why Choose us</h3>
                <div class="row">
                    <div class="col-lg-8">
                        <p>
                            Our core values are driven by Curiosity, Ambition & Humility in equal parts, Agility & Perseverance in doing whatever it takes to live up to our Commitments, and a Shared, Collaborative Accountability.
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <img src="https://www.sapphiresolutions.net/images/why_choose_us_icons/why_banner.png"/>                    </div>
                </div>
              
            </div>
        </div>
        <div class="col-md-6">
            <div class="card card-2">
                
                <h3>What We Do</h3>
                <div class="row">
                    <div class="col-lg-8">
                        <p>
                            We bring our track record and expertise in R&D, Technology Development, Scale-Up, large scale Industrial Fermentation and Botanical Extraction with solid, unparalleled customer relationships.
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <img src="https://img.freepik.com/premium-vector/teamwork-office-problem-solving-brainstorming-what-concept-vector-illustration_143808-726.jpg" />
                    </div>
                </div>
                <p>
                </p>
            </div>
        </div><br />
        <div class="col-lg-3"></div>
        <div class="col-md-6">
            <div class="card card-3">
                <h3>Experience</h3>
                <div class="row">
                    <div class="col-lg-8">
                        <p>
                            Our core values are driven by Curiosity, Ambition & Humility in equal parts, Agility & Perseverance in doing whatever it takes to live up to our Commitments, and a Shared, Collaborative Accountability.
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <img src="https://img.freepik.com/free-vector/ui-ux-team-concept-illustration_114360-11223.jpg?w=740&t=st=1713950769~exp=1713951369~hmac=c4409c52b1d78996a47fa5da1dccdfee7703c71cba55691b99f78b3df2827ec1" />
                    </div>
                </div>
              
            </div>
        </div>        <div class="col-lg-3"></div>

    </div>
</div>-->
<section class="articles" style="margin-top: 32px;">
    <article>
        <div class="article-wrapper">
            <figure>

                <!--<img src="../../../../assets/img/Nutrigene/why.webp" alt="" />-->
                <img src="https://www.sapphiresolutions.net/images/why_choose_us_icons/why_banner.png" style="width:100%"/>

        </figure>
            <div class="article-body">
                <h3 style="text-align:center">Why Choose us</h3>
                <p>
                    Our core values are driven by Curiosity, Ambition & Humility in equal parts, Agility & Perseverance in doing whatever it takes to live up to our Commitments, and a Shared, Collaborative Accountability.
                </p>

            </div>
        </div>
    </article>

    <article>
        <div class="article-wrapper">
            <figure>
                <img style="width:100%" src="https://img.freepik.com/premium-vector/teamwork-office-problem-solving-brainstorming-what-concept-vector-illustration_143808-726.jpg" />

                <!--<img src="../../../../assets/img/Nutrigene/what.webp" alt="" />-->
            </figure>
            <div class="article-body">
                <h3 style="text-align:center">What We Do</h3>
                <p>
                    We bring our track record and expertise in R&D, Technology Development, Scale-Up, large scale Industrial Fermentation and Botanical Extraction with solid, unparalleled customer relationships.
                </p>

            </div>
        </div>
    </article>

    <article>
        <div class="article-wrapper">
            <figure>
                <!--<img src="../../../../assets/img/Nutrigene/Experience1.webp" alt="" />-->
                <img style="width:100%" src="https://img.freepik.com/free-vector/ui-ux-team-concept-illustration_114360-11223.jpg?w=740&t=st=1713950769~exp=1713951369~hmac=c4409c52b1d78996a47fa5da1dccdfee7703c71cba55691b99f78b3df2827ec1" />
            </figure>
            <div class="article-body"><h3 style="text-align:center">Experience</h3>
                <p>
                    Our core values are driven by Curiosity, Ambition & Humility in equal parts, Agility & Perseverance in doing whatever it takes to live up to our Commitments, and a Shared, Collaborative Accountability.
                </p>

            </div>
        </div>
    </article>
</section>

<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>
<style>
    @media only screen and (max-width:600px) {
        .wor_0 {
            margin-top: 14px;
            margin-bottom: 30px;
        }
    }

    @media only screen and (max-width: 767px) {
        body {
            font-size: 10px;
            padding: 5%;
        }


        .banner-img {
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            margin-top: -23px;
        }
    }
</style>
<style>
        article {
            --img-scale: 1.001;
            --title-color: black;
            --link-icon-translate: -20px;
            --link-icon-opacity: 0;
            position: relative;
            border-radius: 16px;
            box-shadow: none;
            background: #fff;
            transform-origin: center;
            transition: all 0.4s ease-in-out;
            overflow: hidden;
        }

            article a::after {
                position: absolute;
                inset-block: 0;
                inset-inline: 0;
                cursor: pointer;
                content: "";
            }

            /* basic article elements styling */
            article h2 {
                margin: 0 0 18px 0;
                font-family: poppins;
                font-size: 1.9rem;
                letter-spacing: 0.06em;
                color: var(--title-color);
                transition: color 0.3s ease-out;
            }

        figure {
            margin: 0;
            padding: 0;
            aspect-ratio: 16 / 9;
            overflow: hidden;
        }

        article img {
            max-width: 100%;
            transform-origin: center;
            transform: scale(var(--img-scale));
            transition: transform 0.4s ease-in-out;
        }

        .article-body {
            padding: 24px;
        }

        article a {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            color: #28666e;
        }

            article a:focus {
                outline: 1px dotted #28666e;
            }

            article a .icon {
                min-width: 24px;
                width: 24px;
                height: 24px;
                margin-left: 5px;
                transform: translateX(var(--link-icon-translate));
                opacity: var(--link-icon-opacity);
                transition: all 0.3s;
            }

        /* using the has() relational pseudo selector to update our custom properties */
        article:has(:hover, :focus) {
            --img-scale: 1.1;
            --title-color: #28666e;
            --link-icon-translate: 0;
            --link-icon-opacity: 1;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        }


        /************************
    Generic layout (demo looks)
    **************************/

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        body {
            margin: 0;
            padding: 48px 0;
            font-family: poppins;
            font-size: 1.2rem;
            line-height: 1.6rem;
            background-image: linear-gradient(45deg, #7c9885, #b5b682);
            min-height: 100vh;
        }

        .articles {
            display: grid;
            max-width: 1200px;
            margin-inline: auto;
            padding-inline: 24px;
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            gap: 24px;
        }

        @media screen and (max-width: 960px) {
            article {
                container: card/inline-size;
            }

            .article-body p {
                display: none;
            }
        }

        @container card (min-width: 380px) {
            .article-wrapper {
                display: grid;
                grid-template-columns: 100px 1fr;
                gap: 16px;
            }

            .article-body {
                padding-left: 0;
            }

            figure {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }

                figure img {
                    height: 100%;
                    aspect-ratio: 1;
                    object-fit: cover;
                }
        }

        .sr-only:not(:focus):not(:active) {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        .article-wrapper {
            border: 1px solid lightblue;
            border-radius: 1px 1px 16px 16px;
        }
</style>
