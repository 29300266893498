import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';


import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
@Component({
  selector: 'app-download-brochers',
  templateUrl: './download-brochers.component.html',
  styleUrls: ['./download-brochers.component.scss']
})
export class DownloadBrochersComponent implements OnInit {
    HomeUrl: any;
    dataResult: any;
    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

        });
    }

    ngOnInit(): void {
        this.Get_Brochur();
    }
    Get_Brochur() {
        debugger
        var url = "api/Nutrigene/Get_Brochurs";
        this.generalService.GetData(url).then(data => {
            this.dataResult = data;
          
        }, err => {
            // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        })
    }
}
